const generate_style_page = {
  image_generation: 'Image generation',
  regenerate: 'Regenerate',
  edit: 'Edit',
  download: 'Download',
  validate: 'Validate',
  image_downloaded: 'Image downloaded successfully',
  companion_is_generating: "A little patience... I'm generating your image!",
  generation_done: 'And here is the result! Do you like it?',
  good_to_know: 'Good to Know',
  good_to_know_texts: {
    1: "Have you ever tried your luck at the slot machine? Some say they've hit the jackpot!",
    2: 'Did you know that when a post reaches 100 likes, a tree is planted? Do good while sharing your favorite moments!',
    3: 'Are you familiar with our exclusive digital business card feature? Create and share yours to stand out in the network!',
    4: "Mall is coming soon! Expect incredible discounts on big brands. Don't forget to sign up to be the first to know!",
    5: 'Do you like challenges? Join our game leaderboards and show everyone who is the real champion in the app!',
    6: 'Collect seeds and drops to access special content or to advance faster in our games. Every interaction counts!',
    7: 'Turn your interactions into a positive impact: every time you use our app, you contribute to eco-friendly initiatives.',
    8: 'Discover our exclusive packs in the store: buy seeds, coins, drops, or tickets and accelerate your progress in the app!',
    9: "Every day, you have the opportunity to recover your daily resources. Don't forget to log in to not miss them!",
    10: 'With every friend you refer, you earn valuable resources. The more you invite, the more you earn, so share your referral code now!',
    11: 'Participate in our daily quests to accumulate even more seeds and coins. Each day brings new challenges and rewards!',
    12: 'Have you watched an ad to get free resources today? Watch an ad and earn drops instantly!',
    13: 'Did you know that you can track your statistics in real time in our app? Find out who views your posts and who interacts with them!',
    14: 'Every liked post brings our community closer to the next tree planting. Actively participate in our green mission!',
    15: 'Use our coins to play games or to obtain exclusive advantages. The more you have, the more you can do!',
    16: 'Discover new ways to earn rewards every day. Visit our challenge section and start accumulating benefits now!',
  },
}

export default generate_style_page
