import React, { memo, useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { useGetWhitelabel } from './Hooks'

const BASE_URL = window.origin
const INITIAL_THUMBNAIL = '/logo.png'

const SEO = memo(({ seoData = {} }) => {
  const { title, ogTitle, ogDescription, image, ogUrl } = seoData
  const whitelabel = useGetWhitelabel()
  const initialSEO = useMemo(() => {
    return {
      favicon: whitelabel?.logoSmall || '/favicon.ico',
      thumbnail: whitelabel?.logoSmall || INITIAL_THUMBNAIL,
      description: `${whitelabel?.whiteLabelName || 'Clink'} allow you to create your virtual online profile to easily share all of your contact information and social media links to anyone.`,
      title: `${whitelabel?.whiteLabelName || 'Clink'} - Create a profile that brings together your digital identity`,
    }
  }, [whitelabel])

  return (
    <Helmet>
      <title>{title || initialSEO.title}</title>
      <link
        id="favicon"
        rel="icon"
        href={initialSEO.favicon}
        type="image/x-icon"
      />
      <meta property="og:title" content={ogTitle || initialSEO.title} />
      <meta
        property="og:description"
        content={ogDescription || initialSEO.description}
      />
      <meta property="og:image" content={image || initialSEO.thumbnail} />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="300" />
      <meta property="og:url" content={ogUrl || BASE_URL + '/sign-in'} />

      <meta property="twitter:title" content={ogTitle || initialSEO.title} />
      <meta
        property="twitter:description"
        content={ogDescription || initialSEO.description}
      />
      <meta property="twitter:image" content={image || initialSEO.thumbnail} />
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:url" content={ogUrl || BASE_URL + '/sign-in'} />
    </Helmet>
  )
})
export default SEO
