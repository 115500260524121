import { useState, useEffect, useCallback } from 'react'
// import { GrCircleInformation } from 'react-icons/gr'
import { getClientIp } from '@/Utils/getIpUser'
// import { createReportUser } from '../../modules/createReportUserFunction'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { Modal } from '../Modals/ModalWrapper/Modal'
import { OptionsReport } from '../OptionsReport/OptionsReport'
import { useTranslation } from 'react-i18next'

const StyledButtonReport = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1px;
  bottom: 8px;
  right: 14px;
  cursor: pointer;

  svg {
    width: 28px !important;
    height: 28px !important;
  }

  span {
    opacity: 1;
    margin-left: 12px;
    font-size: 0.875em;
    white-space: nowrap;
  }
`
export const WrapperReportText = styled.div`
  width: 400px;
  max-width: 100%;
  text-align: end;
  h4 {
    text-align: center;
    margin-bottom: 16px;
  }
  textarea {
    width: 400px;
    max-width: 100%;
    height: 176px;
    border: 2px solid #f4f5f9;
    border-radius: 12px;
    padding: 12px 10px 0;
    resize: none;
    color: #080808;
  }
`

export const ButtonReport = ({ currentProfile, currentUser }) => {
  const [openModalReport, setOpenModalReport] = useState(false)
  const [openTextareaReportOptions, setOpenTextareaReportOptions] =
    useState(false)
  const [loading, setLoading] = useState(false)
  const [successSendData, setSuccessSendData] = useState(false)
  const [valueReport, setValueReport] = useState({
    isReportUid: currentProfile?.uid,
    hasReportUid: '',
    option: '',
    message: '',
    emailOfReportingUser: currentUser?.email || 'VISITOR',
    date: Date.now(),
  })
  const { t } = useTranslation()

  const getHasReportUid = async uid => {
    if (uid) return uid
    try {
      const ip = await getClientIp()
      return ip
    } catch (err) {
      console.log(err)
      return ''
    }
  }

  const fetch = useCallback(async () => {
    const uid = currentUser?.uid || ''
    const hasReportUid = await getHasReportUid(uid)
    setValueReport(state => ({
      ...state,
      hasReportUid,
    }))
  }, [currentUser?.uid])

  useEffect(() => {
    fetch()
    return () => fetch()
  }, [fetch])

  const handleOnChange = event => {
    const { name, value } = event.target
    setValueReport({ ...valueReport, [name]: value })
  }

  const handleSubmit = async value => {
    if (loading) return null
    setLoading(true)
    try {
      // await createReportUser(value)
      setOpenTextareaReportOptions(false)
      setOpenModalReport(false)
      setSuccessSendData(true)
      setValueReport({ ...valueReport, message: '', option: '' })
      setLoading(false)
    } catch (err) {
      toast.error(err)
      setLoading(false)
    }
  }

  if (currentUser?.uid === currentProfile?.uid) return null

  return (
    <>
      <Modal
        isOpen={openModalReport}
        onDismiss={() => setOpenModalReport(false)}
        onReady={() => setOpenModalReport(true)}
        title={t('options Report.10')}
      >
        <div className="wrapper-options-report">
          {[...Array(10).keys()].map((item, index) => (
            <OptionsReport
              key={index}
              text={t(`options Report.${index + 1}`)}
              openOption={() => {
                setOpenModalReport(false)
                setOpenTextareaReportOptions(true)
              }}
              setValueReport={setValueReport}
            />
          ))}
        </div>
      </Modal>
      <Modal
        isOpen={openTextareaReportOptions}
        onDismiss={() => setOpenTextareaReportOptions(false)}
        onReady={() => setOpenTextareaReportOptions(true)}
        cancel={false}
        loading={loading}
        actions={[
          {
            title: t('options Report.12'),
            onClick: () => {
              setOpenTextareaReportOptions(false)
              setOpenModalReport(true)
            },
          },
          {
            title: t('options Report.13'),
            onClick: () => handleSubmit(valueReport),
          },
        ]}
      >
        <WrapperReportText>
          <h4>{t('options Report.11')}</h4>
          <textarea
            name="message"
            placeholder={t('options Report.14')}
            onChange={e => handleOnChange(e)}
            value={valueReport.message}
          ></textarea>
        </WrapperReportText>
      </Modal>
      <Modal
        isOpen={successSendData}
        onDismiss={() => setSuccessSendData(false)}
        onReady={() => setSuccessSendData(true)}
        cancel={false}
        loading={loading}
      >
        <WrapperReportText>
          <h3 style={{ textAlign: 'center' }}>{t('options Report.16')}</h3>
          <p style={{ textAlign: 'start' }}>{t('options Report.15')}</p>
          <p style={{ textAlign: 'start' }}>{t('options Report.17')}</p>
        </WrapperReportText>
      </Modal>
      {/* <StyledButtonReport className="btn-report">
        <span onClick={() => setOpenModalReport(true)}>Report abuse</span>
      </StyledButtonReport> */}
    </>
  )
}
