export default build =>
  build.query({
    query: slug => {
      return {
        url: `/whitelabel/${slug}`,
        method: 'GET',
      }
    },
    providesTags: ['Whitelabel'],
  })
