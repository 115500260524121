import useUploadImage from '@/Hooks/useUploadImage'
import { useCallback } from 'react'
import { useEditCurrentUser } from '../../Contexts/EditProfileContext'
import ThumbnailPreview from '@/Components/ThumbnailPreview'
import { useTranslation } from 'react-i18next'
import Text from '@/Components/Texts/Text'
import { Slider } from '@mui/material'
import { useGetWhitelabel } from '@/Hooks'

const LogoQrCode = ({ route }) => {
  const { t } = useTranslation()
  const whitelabel = useGetWhitelabel()
  const { uploadImage } = useUploadImage()
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser()

  const handleChanges = useCallback(
    (key, value) => {
      let newEditCurrentUser = JSON.parse(JSON.stringify(editCurrentUser))
      newEditCurrentUser.qrcode.attributes[key] = value
      setEditCurrentUser(newEditCurrentUser)
    },
    [editCurrentUser, setEditCurrentUser],
  )

  const handleChangeThumbnail = useCallback(
    async newThumbnail => {
      if (newThumbnail === whitelabel?.logoSmall) {
        return handleChanges('logoImage', newThumbnail)
      }
      const { src } = await uploadImage(newThumbnail, true)
      handleChanges('logoImage', src)
    },
    [uploadImage, handleChanges, whitelabel?.logoSmall],
  )

  return (
    <div className="w-full">
      <div className="mb-6">
        <ThumbnailPreview
          width={200}
          height={200}
          thumbnail={
            editCurrentUser?.qrcode?.attributes?.logoImage ||
            whitelabel?.logoSmall
          }
          setThumbnail={isDefault => handleChangeThumbnail(isDefault)}
          label={t('edit_profil.appearance.logo.preview_text')}
          initialValue={whitelabel?.logoSmall}
          variant="secondary"
        />
      </div>
      <Text>{t('edit_profil.appearance.logo.label_slider')}</Text>
      <Slider
        min={0.1}
        max={0.22}
        value={editCurrentUser?.qrcode?.attributes?.logoScale || 0.1}
        step={0.02}
        onChange={(e, value) => handleChanges('logoScale', value)}
      />
    </div>
  )
}

export default LogoQrCode
