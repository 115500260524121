const others = {
  complete_your_welcome_quests: 'Complete your welcome quests',
  share_business_card: {
    title: 'Share my card',
  },
  scan: {
    waitings: 'Scanning in progress ...',
    empty_permissions: 'Missing permission',
  },
  select_value: 'Select a value',
  select_date: 'Select a date',
  negative_prompt_explanation_page: 'Negative prompt',
  see_more: 'See more',
  see_less: 'See less',
  copy_text_area: 'copy',
  delete_all_notifications: 'Delete all notifications',
  private_profile: 'This profile is private',
  or_continue_with: 'Or continue with',
  participants_on_this_post: 'participant{{plurial}} on this post',
  water: 'Water',
  watered: 'Watered',
  watered_your_seed: 'watered your seed',
  watered_the_seed: 'watered the seed',
  no_internet_connection: 'No internet connection',
  my_budly_profile: 'My profile : ',
  end_of_the_list: 'Nothing more to display',
  errors: {
    username_already_used: 'This username is already used',
  },
  gallery_of: "{{name}}'s gallery",
  free: 'Free',
  submit: 'Submit',
  cancel: 'Cancel',
  delete: 'Delete',
  save: 'Save',
  modifications_saved: 'Changes have been saved !',
}

export default others
