const onboarding = {
  page_title: 'Onboarding',
  optional: 'Optional',
  about_title: 'About',
  interests: 'Interests',
  selected: 'Selected',
  calendar: 'Calendar',
  username_title: 'Username',
  your_username: 'Your username',
  referral_code: 'Referral code',
  your_godfather: 'Your godfather',
  about: {
    male: 'Male',
    female: 'Female',
    society: 'Society',
  },
  tags: {
    Business: 'Business',
    Creatives: 'Creatives',
    Education: 'Education',
    Entertainment: 'Entertainment',
    Fashion: 'Fashion',
    Government_Politics: 'Government & Politics',
    Food_Beverages: 'Food & Beverages',
    Health_Wellness: 'Health & Wellness',
    Tech: 'Tech',
    Travel_Tourism: 'Travel & Tourism',
    Other: 'Other',
  },
  title: 'Tell us about you',
  subtitle: 'For a personalized experience',
  who_are_you: 'Who are you ?',
  username: 'Your username',
  username_information: 'Only letters and numbers are allowed *',
  sponsorUID: 'Sponsor code',
  firstname: 'Your first name',
  lastname: 'Your last name',
  lastname_title: 'Last name',
  birth_date: 'Your birthdate',
  birth_date_title: 'Birthdate',
  business_name: 'Your business name',
  select_categories: 'Select three categories that best describes your profile',

  continue: 'Continue',
  verification_code: {
    verification: 'Verification',
    title: 'Checking your email address',
    subtitle: 'Click on the link or enter the code we sent you!',
    back_to_verification: 'Go to account verification',
    info: 'We sent the verification code to ',
    resend: 'Resend the email',
    verify_your_account: 'Verify your account',
    enter_your_verification: 'Enter your verification code',
  },
  personalize_your: 'Personalize your experience',
  creating_your_profile: 'Creating your profile',
  enter_your_information: 'Enter your information',
  step1: {
    welcome: 'Would you like to create a personal or professional profile?',
    profile: 'Profile',
    choose_individual:
      "Choose 'personal' to create a profile that reflects you or 'professional' to create a profile that reflects your company.",
    individual: {
      name: 'First name',
      title: 'Individual',
      subtitle:
        'Create a profile that represents you and interact with other users.',
    },
    business: {
      name: 'Business name',
      title: 'Business',
      subtitle: 'Use a profile to promote your activity within the app.',
    },
  },
  step2: {
    complete_your_budly: 'Complete your profile',
    introduce_yourself: 'Introduce yourself to the community members.',
    your_profile_is: 'Your profile is created!',
    select_a_photo_and_a_banner:
      'Select a photo and a banner to personalize your profile!',
    create_your_profile: 'Create your profile',
    enter_your_first_name:
      'Enter your first name to personalize your profile. If you have a referral code, enter it here to enjoy exclusive benefits!',
    help_others: 'Help other network members get to know you better.',
    describe_yourself: 'Describe yourself to the community.',
  },
}

export default onboarding
