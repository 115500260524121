const modal_download_app = {
  title: 'Bien joué ! Vous voulez le même profil ?',
  subtitle: 'Obtenez pour créer votre propre profil numérique',
  btn_label: 'Obtenez',
  download_the_budly_app: 'Téléchargez l’application',
  explore_budly:
    'Explorez les fonctionnalités uniques : plantez des arbres, personnalisez votre profil, adoptez des compagnons NFT et bien plus encore !',
}

export default modal_download_app
