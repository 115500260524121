const modal_download_app = {
  title: '¡Buen! ¿Quieres el mismo perfil?',
  subtitle: 'Obtén app para crear tu propio perfil digital',
  btn_label: 'Obtén app',
  download_the_budly_app: 'Descarga la aplicación',
  explore_budly:
    'Explora las características únicas: planta árboles, personaliza tu perfil, adopta compañeros NFT y mucho más.',
}

export default modal_download_app
