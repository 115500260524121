import React from 'react'
import { memo } from 'react'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'
import * as routes from './routes'
import DashboardLayout from '@/Components/Layouts/DashboardLayout'
import MainLayout from '@/Components/Layouts/MainLayout'

const router = createBrowserRouter([
  {
    path: '/*',
    element: (
      // <>
      //   <MainLayout>
      //     <routes.Home />
      //   </MainLayout>
      // </>
      <Navigate to="/sign-in" />
    ),
  },
  {
    path: '/404',
    element: <Navigate to="/sign-in" />,
  },
  {
    path: 'dashboard',
    element: (
      <MainLayout>
        <DashboardLayout />
      </MainLayout>
    ),
    children: [
      {
        path: 'profile/*',
        element: <routes.DashboardProfile />,
      },
      {
        path: 'team/*',
        element: <routes.DashboardTeam />,
      },
      {
        path: 'admin/*',
        element: <routes.DashboardAdmin />,
      },
      {
        path: 'settings/*',
        element: <routes.DashboardSettings />,
      },
      {
        path: 'statistics/*',
        element: <routes.DashboardStatistics />,
      },
      {
        path: 'shop/*',
        element: <routes.Shop />,
      },
      {
        path: '*',
        element: <Navigate to="profile/informations" />,
      },
    ],
  },
  {
    path: '/:slug',
    element: (
      <MainLayout>
        <routes.Profile />
      </MainLayout>
    ),
  },
  {
    path: '/:whitelabelSlug/u/:slug',
    element: (
      <MainLayout>
        <routes.Profile />
      </MainLayout>
    ),
  },
  {
    path: '/c/:cardId',
    element: (
      <MainLayout>
        <routes.BusinessCard />
      </MainLayout>
    ),
  },
  {
    path: '/v/verification',
    element: (
      <MainLayout>
        <routes.Verification />
      </MainLayout>
    ),
  },
  {
    path: '/v/reset-pin-code',
    element: (
      <MainLayout>
        <routes.ResetPinCode />
      </MainLayout>
    ),
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
  {
    path: 'sign-in',
    element: (
      <MainLayout>
        <routes.SignIn />
      </MainLayout>
    ),
  },
  {
    path: '/:whitelabelSlug/sign-in',
    element: (
      <MainLayout>
        <routes.SignIn />
      </MainLayout>
    ),
  },
  {
    path: 'sign-up',
    element: (
      <MainLayout>
        <routes.SignUp />
      </MainLayout>
    ),
  },
  {
    path: '/:whitelabelSlug/sign-up',
    element: (
      <MainLayout>
        <routes.SignUp />
      </MainLayout>
    ),
  },
  {
    path: 'onboarding',
    element: (
      <MainLayout>
        <routes.OnboardingPage />
      </MainLayout>
    ),
  },
  {
    path: 'dl-app',
    element: (
      <MainLayout>
        <routes.DownloadApp />
      </MainLayout>
    ),
  },
])

export const Router = memo(() => {
  return <RouterProvider router={router} />
})
