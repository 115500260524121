const others = {
  complete_your_welcome_quests: 'Completa tus misiones de bienvenida',
  share_business_card: {
    title: 'Compartir mi tarjeta',
  },
  scan: {
    waitings: 'Escaneo en curso ...',
    empty_permissions: 'Permiso faltante',
  },
  select_value: 'Seleccione un valor',
  negative_prompt_explanation_page: 'Mensaje negativostrong',
  see_more: 'Ver más',
  see_less: 'Ver menos',
  copy_text_area: 'copiar',
  delete_all_notifications: 'Eliminar todas las notificaciones',
  private_profile: 'Este perfil es privado',
  or_continue_with: 'O continuar con',
  participants_on_this_post: 'participant{{es}} en este post',
  water: 'Regar',
  watered: 'Riegan',
  watered_your_seed: 'riegan tu semilla',
  watered_the_seed: 'riegan la semilla',
  no_internet_connection: 'No hay conexión a Internet',
  my_budly_profile: 'Mi perfil :',
  end_of_the_list: 'Nada más que mostrar',
  errors: {
    username_already_used: 'Este nombre de usuario ya está en uso',
  },
  gallery_of: 'Galería de {{name}}',
  free: 'Gratis',
  submit: 'Enviar',
  cancel: 'Cancelar',
  delete: 'Eliminar',
  save: 'Ahorrar',
  modifications_saved: '¡Los cambios han sido guardados!',
}

export default others
