import { useCosmetics } from '../../Contexts/CosmeticsContext'
import { useMemo } from 'react'
import { formatColors } from '@/Utils/formatColors'
import Text from '@/Components/Texts/Text'
import ColorSelection from '@/Components/ColorSelection'
import { useTranslation } from 'react-i18next'
import { useEditCurrentUser } from '../../Contexts/EditProfileContext'
import useCurrentUser from '@/Hooks/useCurrentUser'
import useCurrentMember from '@/Hooks/useCurrentMember'

const DEFAULT_COLOR = '#ffffff'

const BackgroundQrCode = ({ route }) => {
  const { t } = useTranslation()
  const cosmetics = useCosmetics()
  const { currentUser } = useCurrentUser()
  const { currentMember } = useCurrentMember()
  const user = currentMember || currentUser
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser()

  const { colors, customColor } = useMemo(() => {
    return {
      colors: formatColors({
        colors: cosmetics?.['qr-background-color'] || [],
        defaultColor: DEFAULT_COLOR,
      }),
      customColor: cosmetics?.['qr-background-color-custom']?.[0] || null,
    }
  }, [cosmetics])

  const selectedColor = useMemo(() => {
    return editCurrentUser?.qrcode?.attributes?.backgroundColor?.toLowerCase?.()
  }, [editCurrentUser?.qrcode?.attributes?.backgroundColor])

  const usedColor = useMemo(
    () =>
      (
        user?.qrcode?.attributes?.backgroundColor || DEFAULT_COLOR
      ).toLowerCase(),
    [user?.qrcode?.attributes?.backgroundColor],
  )

  const onAddToCart = item => {
    setEditCurrentUser(prev => {
      let newCurrentUser = JSON.parse(JSON.stringify(prev))
      newCurrentUser.qrcode.attributes.backgroundColor = item.value
      return newCurrentUser
    })
  }

  if (!customColor) return null

  return (
    <div className="relative flex-1">
      <div className="px-4">
        <Text>{t('edit_profil.appearance.color_theme.color')}</Text>
        <ColorSelection
          type="qr-background-color"
          colors={colors}
          customColor={customColor}
          selectedColor={selectedColor}
          usedColor={usedColor}
          onAddToCart={onAddToCart}
        />
      </div>
    </div>
  )
}

export default BackgroundQrCode
