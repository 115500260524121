import { Button } from '@/Components/Buttons/Button'
import { usePutCurrentUserMutation } from '@/Services/modules/users'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { onConnectShop, onCreateShop, onSwitchLiveStore } from './functions'

export const CreateShopButton = ({ user }) => {
  const { t } = useTranslation()
  const [putCurrentUser] = usePutCurrentUserMutation()
  const [loading, setLoading] = useState(false)
  const handleCreateShop = async () => {
    setLoading(true)
    try {
      const createRes = await onCreateShop({ user })
      if (createRes.status !== 200) {
        throw new Error('Une erreur est survenue')
      }

      const { store_id, live_publishable_key, live_secret_key } = createRes.data
      const connectRes = await onConnectShop({
        email: user.email,
        storeID: store_id,
      })

      if (connectRes.status !== 200) {
        throw new Error('Une erreur est survenue')
      }

      const { id, password } = connectRes.data
      await Promise.all([
        onSwitchLiveStore({ storeApiKey: live_secret_key }),
        putCurrentUser({
          body: {
            shopRocket: {
              store_id,
              live_publishable_key,
              live_secret_key,
              user_id: id,
              password,
            },
          },
          userId: user.uid,
        }),
      ])

      toast.success(t('shop.shop_creation_success'))
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  if (user?.shopRocket?.store_id) {
    return null
  }

  return (
    <Button
      label="Créer un shop"
      onClick={handleCreateShop}
      className="w-full"
      loading={loading}
    />
  )
}
