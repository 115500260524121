import Text from '@/Components/Texts/Text'
import React from 'react'
import { useOnboardingContext } from '../OnBoardingContext'
import clsx from 'clsx'
import { useTheme } from '@/Hooks'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import { LineBuilding, LineCheck2, LineUser } from '@/Assets/icons/components'
import ProfilePreviewTemplate from './ProfilePreviewTemplate'

const TITLE = 'onboarding.step1.welcome'
const SUBTITLE = 'onboarding.step1.choose_individual'

const CHOICES = [
  {
    icon: <LineUser size={14} />,
    name: 'onboarding.step1.individual.name',
    title: 'onboarding.step1.individual.title',
    subtitle: 'onboarding.step1.individual.subtitle',
    value: false,
  },
  {
    icon: <LineBuilding size={14} />,
    name: 'onboarding.step1.business.name',
    title: 'onboarding.step1.business.title',
    subtitle: 'onboarding.step1.business.subtitle',
    value: true,
  },
]

const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.colors.grey1};
  border-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.primary : 'transparent'};
`

const Check = ({ isSelected }) => {
  if (!isSelected) {
    return null
  }
  return (
    <div className="absolute top-4 right-4 bg-primary w-6 h-6 rounded-lg flex flex-col justify-center items-center">
      <LineCheck2 size={18} color="#fff" className="mb-1" />
    </div>
  )
}

const ProfileTypeSelector = () => {
  const { values, setValues } = useOnboardingContext()
  const { theme } = useTheme()
  const { t } = useTranslation()
  return (
    <div className="flex flex-row justify-between">
      {CHOICES.map(({ icon, name, title, subtitle, value }, index) => {
        const isSelected = values.businessMode === value
        return (
          <StyledWrapper
            key={index}
            className={clsx(
              'relative w-[calc(50%-4px)] overflow-hidden p-4 items-center flex flex-col cursor-pointer rounded-3xl border-2',
              isSelected ? 'border-2 border-primary' : 'border-transparent',
              'tablet:rounded-xl',
            )}
            onClick={() =>
              setValues(prev => {
                return { ...prev, businessMode: value }
              })
            }
            isSelected={isSelected}
          >
            <Check isSelected={isSelected} />
            <div className="w-full px-10 pt-2">
              <ProfilePreviewTemplate icon={icon} name={t(name)} />
            </div>
            <Text
              className="text-base tablet:text-sm"
              color={isSelected ? theme.colors.primary : theme.colors.grey3}
            >
              {t(title)}
            </Text>
            <Text
              regular
              color={theme.colors.grey2}
              className="text-sm text-center mt-2"
            >
              {t(subtitle)}
            </Text>
          </StyledWrapper>
        )
      })}
    </div>
  )
}

const ProfileTypeSelectorSection = {
  title: TITLE,
  subtitle: SUBTITLE,
  component: ProfileTypeSelector,
  step: 0,
  section: 0,
}

export default ProfileTypeSelectorSection
