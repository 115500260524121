import React from 'react'
import { useProfileContext } from './Contexts/ProfileContext'
import IconButton from '@/Components/Buttons/IconButton'
import { LineArrowRight, LineExport } from '@/Assets/icons/components'

const ProfileContentIcon = ({ share }) => {
  const { profile } = useProfileContext()
  const navigator = typeof window !== 'undefined' && window.navigator
  const canShare =
    navigator && navigator?.share && navigator.canShare?.({ url: share })

  const onShare = async e => {
    e.stopPropagation()
    e.preventDefault()

    try {
      await navigator?.share({ url: share })
    } catch (err) {
      console.log(err)
      window.open(share)
    }
  }

  return (
    <>
      {!profile.hideSharingBtnContent && canShare ? (
        <IconButton onClick={onShare} icon={<LineExport />} color="#FFFFFF" />
      ) : (
        <LineArrowRight />
      )}
    </>
  )
}
export default ProfileContentIcon
