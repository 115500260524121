const casino = {
  coins_left: 'Monedas restantes',
  slots_budly: {
    no_free_slot: 'No hay tiradas gratuitas',
    in_progress: 'EN CURSO',
    spin: 'GIRAR',
    starting_bet: 'Apuesta inicial',
    win_multiple: 'Gana múltiples',
    each_spin_offers_a_chance_to_win:
      '¡Cada giro ofrece una oportunidad de ganar!',
    ready_go_jackpot: '¡Listo, adelante, jackpot!',
    hit_the_jackpot_today: '¡Gana el jackpot hoy!',
    start_a_new_game: 'Comienza un nuevo juego.',
    no_gain_this_time: 'Sin ganancia esta vez.',
    its_not_your_lucky_day: 'No es tu día de suerte.',
    luck_was_not_on_your_side: 'La suerte no estuvo de tu lado.',
    you_have_won: '¡Has ganado!',
    well_done_you_won: '¡Bien hecho, ganaste!',
    absolute_triumph: '¡Triunfo absoluto!',
    luck_is_on_your_side: '¡La suerte está de tu lado!',
    small_bundle_of_resources: 'Pequeño paquete de recursos',
    large_bundle_of_resources: 'Gran paquete de recursos',
    jackpot: 'Jackpot',
    turn_of_luck: 'Giro de la suerte',
    a_large_bundle_of_resources_garanteed:
      'Un gran paquete de recursos garantizado cada {{frequency}} sorteos',
    chance_of_winning: 'probabilidad de ganar',
    multiple: 'múltiple',
    all_multiples: 'Todos los múltiplos',
    try_your_luck:
      '¡Prueba tu suerte en la máquina tragamonedas App para acumular un máximo de recursos!',
    winning_percentage_details: 'Detalles del porcentaje de ganancias',
    chances_of_winning_increase_by:
      'Además de multiplicar tus ganancias, los múltiplos adicionales aumentan tus posibilidades de ganar en {{increase}}.',
    gains: 'ganancias',
    for_each_resources: '{{quantity}} por cada recurso',
  },
}

export default casino
