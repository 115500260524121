const shop = {
  screen_name: 'Shop',
  free_ressources: 'Free Deals',
  seeds_packages: 'Seeds packs',
  coins_packages: 'Coins packs',
  drops_packages: 'Drops packs',
  tickets_packages: 'Tickets packs',
  pack_title: 'Get {{quantity}} ',
  seed: 'seed',
  coin: 'coin',
  drop: 'drop',
  ticket: 'ticket',
  free_subtitle: 'Watch an ad to earn your {{resource}} for free',
  free_seed_button: 'Watch an ad',
  already_recovered: 'Already recovered',
  remaining: 'remaining',
  five_seeds_pack_subtitle:
    'Each purchase increases the money donated to projects...',
  fifty_seeds_pack_subtitle: 'And help the Team develop ...',
  two_hundred_seeds_pack_subtitle: 'A lot of new features for you.',
  tab_all: 'All',
  tab_free: 'Free',
  tab_coins: 'Coins',
  thanks_for_purchase_title: 'Thanks for your purchase!',
  thanks_for_purchase_description:
    'Your purchase has been made, make good use of it!',
  thanks_for_watching_add_description: 'Your reward has been awarded to you!',
  available_soon: 'Available soon',
  shop_creation_success: 'Your shop has been successfully created',
}

export default shop
