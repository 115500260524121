import * as React from 'react'
const SvgPlantSeedSeparated = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 25})`}>
      <path
        fill="#58637D"
        stroke="#58637D"
        d="M12.998 16.238h-.5v4.5a.5.5 0 1 1-1 0v-5.995l.019-.98a6 6 0 0 1 5.981-5.525h2.5a1.5 1.5 0 0 1 1.5 1.5v.5a6 6 0 0 1-6 6zm-7-12a6.5 6.5 0 0 1 6.058 4.137 7.98 7.98 0 0 0-2.496 4.863h-.562a6.5 6.5 0 0 1-6.5-6.5v-1a1.5 1.5 0 0 1 1.5-1.5z"
      />
    </g>
  </svg>
)
export default SvgPlantSeedSeparated
