import { useTheme } from '@/Hooks'
import clsx from 'clsx'
import React from 'react'
import styled, { css } from 'styled-components'

const StyledTextInput = styled.input`
  ${({ theme, variant, disabled, error }) => css`
    font-family: 'Poppins-Medium';
    color: ${variant === 'primary' ? theme.colors.black : theme.colors.grey3};
    background-color: ${variant === 'primary'
      ? theme.colors.grey1
      : theme.colors.white};
    border-radius: ${theme.borderRadius.l};
    padding: ${theme.space.std} ${theme.space.m};
    min-height: ${error ? theme.size.none : theme.size.m};
    height: ${error ? 'calc(100% - 8px)' : '100%'};
    outline: none;
    width: 100%;
    opacity: ${disabled ? 0.5 : 1};
    pointer-events: ${disabled ? 'none' : 'auto'};

    @media screen and (max-height: 732px) {
      padding: ${theme.space.s} ${theme.space.m};
      min-height: ${error ? theme.size.none : '40px'};
    }

    &::placeholder {
      color: ${theme.colors.grey2};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px ${theme.colors.grey1} inset !important;
    }
  `}
`

const TextInput = ({
  value,
  onChange = () => null,
  variant = 'primary',
  register = {},
  className = '',
  childrenClassName = '',
  ...props
}) => {
  const { theme } = useTheme()
  return (
    <StyledTextInput
      value={value}
      onChange={e => onChange(e.target.value)}
      returnKeyType="done"
      selectionColor={theme.colors.black}
      variant={variant}
      autoComplete="off"
      className={clsx(className, childrenClassName)}
      {...props}
      {...register}
    />
  )
}

export default TextInput
