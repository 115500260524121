const modal = {
  header: {
    all: 'All',
  },
  report: {
    title: 'Report this post',
    modal_title: 'Are you sure you want to post this post ?',
  },
  loading: {
    title: 'Loading ...',
  },
  modalPsw: {
    title: 'changing the password',
    subtitle: 'Are you sure you want to change your password ?',
  },
  forgetPsw: {
    title: 'Forgot your password',
    subtitle: 'Enter your email address to reset your password',
    email: 'your e-mail',
  },
  btn: {
    accept: 'Accept',
    decline: 'Decline',
    confirm: 'Confirm',
    cancel: 'Cancel',
    delete: 'Delete',
    back: 'Back',
    finish: 'Finish',
    gallery_only: 'Gallery only',
  },
  editPost: {
    edit_btn: 'Edit this post',
    remove_btn: 'Delete this post',
  },

  deletepost: {
    title: 'Are you sure you want to delete this post ?',
  },
  publishPost: {
    create_you_post: 'Create a post',
    you_should_pay:
      'To share your post on your news feed, you must spend a seed so users can water it.',
    all_spent_seeds_publication:
      'Find all the seeds spent by putting your publication online.',
    post_publish_price_title: 'Post Publication',
    description_publish_price_title: 'Description ({{length}} characters)',
    hashtag_publish_price_title: 'Hashtag ({{length}})',
    image_publish_price_title: 'Image ({{length}})',
    total_publish_price_title: 'Total Expense',
    plant_a_seed: 'Plant a seed',
    publish: 'Publish',
    get_seeds: 'Get seeds',
    btn_photo: 'Photo',
    btn_AI: 'AI',
    btn_tag: 'Tag',
    change_confirmation: 'Change Confirmation',
    are_you_sure: 'Are you sure you want to edit this post?',
    free_editing: 'Free Editing',
  },
  modal_ai_companion: {
    title: 'Generate images instantly with the help of your companion!',
    subtitle:
      'Let yourself be guided by your companion in creating images with our artificial intelligence tool.',
    btn_discover_companions: 'Discover the companions',
    btn_not_now: 'Not now',
  },
  publish_gallery_in_post: 'Post your photo in the feed',
  customColor: {
    title: 'Customize your color',
  },
  removeAccount: {
    title: 'Delete account',
    subtitle:
      'You are about to delete your account and all your data.\n\nAre you sure you want to do this ?',
    input_confirm: 'To confirm, type "REMOVE MY ACCOUNT" in the field below',
    toastErrorInvalidConfirmation: 'The text entered is invalid',
    toastErrorFieldEmpty:
      'Field empty. You must type "REMOVE MY ACCOUNT" to confirm the deletion',
  },
  removeImagePost: {
    title: 'Deleting the image',
    subtitle:
      'You are about to delete this image.\n\nAre you sure you want to proceed ?',
  },
  chooseThumbnail: {
    title: 'Edit your content thumbnail',
    btn: {
      perso: 'Custom thumbnail',
      default: 'Default thumbnail',
    },
  },
  editOrDeletePicture: {
    title: 'Edit or delete the picture',
    edit: 'Edit the picture',
    delete: 'Delete the picture',
  },
  imageTypeSelection: {
    title: 'Customize your profile',
  },
  imageOriginSelection: {
    title: 'Upload your image',
    choice1: {
      title: 'Upload your own image',
      subtitle: 'From your phone, Facebook, Instagram or Google Drive.',
    },
    choice2: {
      title: 'Photos by Unsplash',
      subtitle: 'Choose from over 500,000 images.',
    },
    choice3: {
      title: 'Animated background',
      subtitle: 'Choose from over 100 animated backgrounds.',
    },
    choice4: {
      title: 'Design with Canva',
      subtitle: 'Design your own cover image in Canva.',
    },
    choice5: {
      title: 'Coverr videos',
      subtitle: 'Choose from over 100,000 videos.',
    },
    choice6: {
      title: 'AI Generation',
      subtitle: 'Generate unique images with the help of AI.',
    },
  },
  editProduct: {
    nfc_option: 'Link your profile to this product via NFC',
  },
  addContent: {
    label_phone: 'Your phone',
  },
  accountDisconnection: {
    title: 'Are you sure you want to log out of your account ?',
    subtitle:
      'The active session will be closed and you will need to log back in to access your account again.',
  },
  imageDeleteConfirmation: {
    title: 'Are you sure you want to delete this image ?',
    subtitle:
      'This action is irreversible and the image will be permanently deleted from your account.',
  },
  contentDeleteConfirmation: {
    title: 'Are you sure you want to delete this content ?',
    subtitle:
      'This action is irreversible and the content will be permanently deleted from your account.',
  },
  postOptions: {
    title: 'Manage interactions with this post',
    subtitle:
      'By posting this post, you help us maintain a respectful community by allowing us to moderate publications. Also, if you want to stop seeing this user’s content, you can choose to block it.',
  },
  blockedUser: {
    title: 'Are you sure you want to block this user?',
    subtitle: 'By blocking this user, you will no longer see its contents.',
  },
  businessCardOption: {
    title: 'Card options',
    option1: 'Edit my card',
    option2: 'View my card',
    option3: 'Delete my card',
  },
  deleteBusinessCard: {
    title: 'Are you sure you want to delete this card',
  },
  checkPhoneNumber: {
    title: 'Verify your number',
    subtitle:
      'Confirm the code sent via SMS to verify your phone number. Your account can then apply for certification.',
    btnLabel1: 'Send a code',
    btnLabel2: 'Confirm',
    modalBtnLabel: 'Verify',
    placeholder: 'Enter your verification number',
  },
  sponsor: {
    title: 'Find your sponsor',
    subtitle:
      'Find your sponsor and get an initial supply of resources, seeds, coins, and water droplets to help kickstart.',
    placeholder: "Enter your sponsor's name",
  },
  insufficientResource: {
    title: 'Insufficient Resources',
    subtitle:
      'It seems you do not have enough resources to perform this action.',
    btnLabelVisitStore: 'Visit the store',
  },
  doubleEarnings: {
    title_double_your_earnings: 'Double Your Earnings ?',
    subtitle_play_with_your_companion:
      "Playing with your companion allows you to double all earnings during the game. However, you will use 25% of your companion's vitality each game.",
    btn_play_with_compagnon: 'Play with My Companion',
    btn_play_without_compagnon: 'Play without My Companion',
  },
  generated_style: {
    image_generation: 'AI image generation',
    pay_and_generate: 'Pay and generate',
    generate_an_image: 'Generate an image',
    spend_coins: 'Spend {{price}} coins to be able to generate your image.',
    generate_the_image: 'Generate the image',
  },
  re_generated: {
    regeneration: 'Regeneration',
    Pay_and_regenerate: 'Pay and regenerate',
    Regenerate_variation: 'Regenerate a variation',
    spend_one_coins:
      'Spend {{price}} coins to be able to re-generate a variation of your image.',
  },
  buy_offer: {
    learn_more: 'Learn more about the offer',
    spend_coins: 'Spend {{price}} coins',
  },
  steps_reward: {
    steps: 'steps',
    steps_on: ' on ',
    validated_steps: '{{steps}} validated steps',
    acquired_coins: '{{coins}} acquired coins !',
    receive_coins_every_day: 'Receive coins every day as you walk',
    every_day_steps:
      "Every day, your step counter is reset at midnight. To make sure you don't lose your daily coins, don't forget to validate your steps before the end of the day",
  },
  deactivateMember: {
    deactivate_selected_member: 'Deactivate the selected member',
    reactivate_this_member: 'Reactivate this member',
    by_deactivating_this_member:
      'By deactivating this member, their account will be suspended and they will no longer be able to access the application. As a result, their profile will no longer be visible to other users.',
    by_reactivating_this_member:
      'By reactivating this member, their access to the application will be restored, allowing them to reconnect. Additionally, their profile will become visible to other users.',
    disable_state: 'Disabled',
    disable: 'Deactivate',
    reactivate: 'Reactivate',
    your_account_has_been_deactivated:
      'Your account has been deactivated by the administrator.',
    the_member_has_been_succesfully:
      'The member has been successfully deactivated.',
    the_member_has_been_reactivated:
      'The member has been successfully reactivated.',
  },
  addToContact: {
    share_this_profile: 'Share this profile',
  },
  socialMedia: {
    share_on_your_favorite: 'Share on your favorite social networks',
    share_on_facebook: 'Share on Facebook',
    share_on_linkedin: 'Share on LinkedIn',
    share_on_twitter: 'Share on Twitter',
    share_on_whatsapp: 'Share on WhatsApp',
    share_on_telegram: 'Share on Telegram',
    share_via_email: 'Share via Email',
  },
  member: {
    delete_profile: 'Delete this profile',
    delete_profile_subtitle:
      'Permanently delete all information related to this profile.',
    role_change: 'Role change',
    role_change_subtitle:
      "Changing a team member's role will modify their permissions in the application.",
    duplicate_profile: 'Profile to duplicate',
    old_profile: 'Old profile',
    new_profile: 'New profile',
  },
}

export default modal
