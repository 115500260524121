const getPostsByHashtag = build =>
  build.query({
    query: ({ hashtag, whitelabel }) => {
      return {
        url: `/posts/hashtag/${hashtag}?whitelabel=${whitelabel}`,
        method: 'GET',
      }
    },
    providesTags: ['PostsByHashtag'],
  })

export default getPostsByHashtag
