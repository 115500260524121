const notifications = {
  notifications: 'Notifications',
  all: {
    title: 'Toutes les notifications',
  },
  daily_quests_reminder: {
    title: 'Quêtes quotidiennes',
    message: 'Complétez toutes vos quêtes quotidiennes',
  },
  daily_rewards_reminder: {
    title: 'Récompenses quotidiennes',
    message: 'Réclamez toutes vos récompenses quotidiennes',
  },
  follow_request: {
    title: 'Demande de follow',
    message: 'vous a envoyé une demande de follow',
  },
  follow_response: {
    title: 'Réponse de follow',
    message: 'a {{response}} votre demande de follow',
  },
  mention_in_image: {
    title: 'Mention dans une image',
    message: 'vous a mentionné dans une image',
  },
  mention_in_post: {
    title: 'Mention dans une publication',
    message: 'vous a mentionné dans une publication',
  },
  mention_in_profile: {
    title: 'Mention dans un profil',
    message: 'vous a mentionné dans un profil',
  },
  new_follow: {
    title: 'Nouveau follow',
    message: 'a commencé à vous suivre',
  },
  new_image: {
    title: 'Nouvelle image',
    message: 'a posté une nouvelle image',
  },
  new_post: {
    title: 'Nouvelle publication',
    message: 'a posté une nouvelle publication',
  },
  new_watering: {
    title: 'Nouvel arrosage',
    message: 'a arrosé votre graine',
  },
  welcome_quests_reminder: {
    title: 'Quêtes de bienvenue',
    message: 'Complétez toutes vos quêtes de bienvenue',
  },
  sponsor_validated: {
    title: 'Parrainage validé',
    message: 'a validé toutes ses quetes de bienvenue',
  },
  mall_is_open: {
    title: 'Mall est disponible !',
    message: 'Venez profiter de nos promotions exclusives !',
  },
}

export default notifications
