const PORT = '127.0.0.1'
//const PORT = '192.168.1.23'

export const Config = {
  API_URL: 'https://europe-west3-gopopme-7353a.cloudfunctions.net/api2',
  API_URL_DEV: `http://${PORT}:5001/gopopme-7353a/europe-west3/api2`,
  ADMIN_UID: 'd2OM80yi4VgNMBUP4ExTlsjAPNI3',
  FILESTACK_API_KEY: 'AkwiaSgsTbuLq3zgwxKlgz',
  BEACONSTAC_API_KEY: '6b2ed0a1c4dfa8f2ac155b433efea08bbb91ae31',
  BEACONSTAC_ORGANIZATION: '126214',
}
