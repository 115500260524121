import { Avatar } from '../Profile/Avatar'
import Banner from './Banner'
import TreeBadge from './TreeBadge'
import Web3Badge from './Web3Badge'
import React from 'react'
import styled, { css } from 'styled-components'

const AvatarWrapper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: ${props => '-' + parseInt(props.avatarSize, 10) / 2}px;
    left: ${theme.space.xs};
  `}
`

const BadgesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  bottom: ${props => '-' + parseInt(props.bottom, 10) / 2}px;
`

const BannerAvatar = ({
  profile,
  avatarSize,
  showEmptyLogo = false,
  ...props
}) => {
  return (
    <>
      <Banner banner={profile.banner} {...props} />
      <AvatarWrapper avatarSize={avatarSize}>
        <Avatar
          shape={profile.styles?.['shape-avatar']}
          size={avatarSize}
          showEmptyLogo={showEmptyLogo}
          profile={profile}
        />
      </AvatarWrapper>
      <BadgesWrapper bottom={avatarSize}>
        <Web3Badge />
        {/* <TreeBadge /> */}
      </BadgesWrapper>
    </>
  )
}

export default BannerAvatar
