import * as React from 'react'
const SvgQrcodeLeftLeaf = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="200pt"
    height="200pt"
    viewBox="0 0 200 200"
    {...props}
  >
    <g transform={`scale(${1})`}>
      <path d="M50 50h50l50 50v50h-50l-50-50" />
      <path
        fill="#58637D"
        stroke="#000"
        d="M150 100c0 27.613-22.387 50-50 50s-50-22.387-50-50 22.387-50 50-50 50 22.387 50 50"
      />
    </g>
  </svg>
)
export default SvgQrcodeLeftLeaf
