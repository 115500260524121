import React, { useState } from 'react'
import { useFormContext } from './FormProvider'
import { Button } from '../Buttons/Button'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const FormSubmitButton = ({ label = null, ...props }) => {
  const [loading, setLoading] = useState(false)
  const { onSubmit } = useFormContext()
  const { t } = useTranslation()
  const handleClick = async () => {
    setLoading(true)
    try {
      await onSubmit()
      toast.success(t('others.modifications_saved'))
    } catch (error) {
      console.error(error)
      toast.error(error.message || t('toastErrorDefault.error'))
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button
      {...props}
      onClick={handleClick}
      label={label || t('others.save')}
      loading={loading}
    />
  )
}

export default FormSubmitButton
