import * as React from 'react'
const SvgLinePlay = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 25})`}>
      <path
        stroke="#58637D"
        strokeWidth={1.5}
        d="M3.688 9.806a7.22 7.22 0 0 1 5.38-5.38 12.84 12.84 0 0 1 5.864 0 7.22 7.22 0 0 1 5.38 5.38 12.84 12.84 0 0 1 0 5.863 7.22 7.22 0 0 1-5.38 5.381 12.8 12.8 0 0 1-5.863 0 7.22 7.22 0 0 1-5.381-5.38 12.84 12.84 0 0 1 0-5.864Z"
      />
      <path
        stroke="#58637D"
        strokeWidth={1.5}
        d="M14.162 11.609c.784.502.784 1.756 0 2.258l-2.647 1.694c-.785.502-1.765-.125-1.765-1.13v-3.387c0-1.004.98-1.631 1.765-1.13z"
      />
    </g>
  </svg>
)
export default SvgLinePlay
