import React from 'react'
import { useOnboardingContext } from '../OnBoardingContext'
import { useGetWhitelabel, useTheme } from '@/Hooks'
import LabeledInput from '@/Components/Controls/LabeledInput'
import { LineCalendar, LineUser } from '@/Assets/icons/components'
import { DatePickerPopoverButton } from '../DatePickerPopoverButton'
import { useTranslation } from 'react-i18next'
import { useLockedField } from '@/Hooks/useLockedField'

const title = 'onboarding.step2.complete_your_budly'
const subtitle = 'onboarding.step2.introduce_yourself'

export const CompleteProfile = () => {
  const whitelabel = useGetWhitelabel()
  const { theme } = useTheme()
  const { values, setValues } = useOnboardingContext()
  const { t } = useTranslation()
  const { isFieldDisabled } = useLockedField()

  const fields = [
    {
      title: 'edit_profil.informations.my_infos.name',
      name: 'firstName',
      placeholder: 'edit_profil.informations.my_infos.name_placeholder',
      adornment: () => <LineUser color={theme.colors.grey2} />,
    },
    {
      title: 'onboarding.lastname_title',
      name: 'lastName',
      placeholder: 'onboarding.lastname',
      adornment: () => <LineUser color={theme.colors.grey2} />,
    },
    {
      title: 'onboarding.birth_date_title',
      name: 'birthday',
      placeholder: 'onboarding.birth_date',
      adornment: () => <LineCalendar color={theme.colors.grey2} />,
      mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
      children: () => {
        return <DatePickerPopoverButton />
      },
      type: 'date',
      format: v => {
        return typeof v === 'string' ? v : new Date(v).toLocaleDateString()
      },
    },
  ]

  return (
    <>
      {fields.map(
        ({
          name,
          title,
          placeholder,
          children = () => null,
          format = v => v,
          ...field
        }) => {
          const disabled = isFieldDisabled({ fieldName: name })
          return (
            <LabeledInput
              key={title}
              color={theme.colors.grey3}
              className="font-light text-sm"
              title={t(title, {
                whitelabelName: whitelabel?.whiteLabelName || 'Clink',
              })}
              placeholder={t(placeholder)}
              value={format(values[name]) || ''}
              onChange={value => {
                setValues({
                  ...values,
                  [name]: value,
                })
              }}
              disabled={disabled}
              require={disabled ? false : true}
              {...field}
            >
              {children()}
            </LabeledInput>
          )
        },
      )}
    </>
  )
}

const CompleteProfileSection = {
  title,
  subtitle,
  component: CompleteProfile,
  step: 2,
  section: 1,
  getDisabled: (values, isFieldDisabled) => {
    const isFirsNameDisabled = isFieldDisabled({ fieldName: 'firstName' })
    const isLastNameDisabled = isFieldDisabled({ fieldName: 'lastName' })

    const isFieldsEmpty =
      (!isFirsNameDisabled && !values.firstName?.trim()) ||
      (!isLastNameDisabled && !values.lastName?.trim()) ||
      !values.birthday?.trim?.()
    return isFieldsEmpty
  },
}

export default CompleteProfileSection
