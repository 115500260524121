import { LineArrowLeft } from '@/Assets/icons/components'
import IconButton from '@/Components/Buttons/IconButton'
import { OptionsLock } from '@/Components/ControlsLocked/OptionsLock'
import { useEditBusinessCard } from '@/Components/Dashboard/Contexts/EditBusinessCardContext'
import { useEditCurrentUser } from '@/Components/Dashboard/Contexts/EditProfileContext'
import BusinessCardList from '@/Components/Dashboard/Profile/BusinessCard/components/BusinessCardList'
import { useGetOptions } from '@/Components/Dashboard/Profile/BusinessCard/options'
import Headings from '@/Components/Dashboard/Profile/Headings'
import Form from '@/Components/Form/Form'
import FormSubmitButton from '@/Components/Form/FormSubmitButton'
import { useLockedField } from '@/Hooks/useLockedField'
import React from 'react'
import { useTranslation } from 'react-i18next'

const TITLE = 'businessCard.title_screen2'

const BusinessCard = () => {
  const { t } = useTranslation()
  const {
    onSubmit,
    editBusinessCard,
    selectedBusinessCard,
    setSelectedBusinessCard,
    edition,
  } = useEditBusinessCard()
  const options = useGetOptions()
  const { haveChanges, onSubmit: onSubmitCurentUser } = useEditCurrentUser()
  const { memberUserId } = useLockedField()

  const handleSubmit = async () => {
    try {
      const lockedMember =
        memberUserId && haveChanges ? onSubmitCurentUser : () => null
      await lockedMember()
      await onSubmit()
    } catch (error) {
      console.error(error)
    }
  }
  if (selectedBusinessCard && editBusinessCard) {
    return (
      <Form onSubmit={handleSubmit}>
        <Headings
          title={edition ? t(TITLE) : t('businessCard.create_business_card')}
          action={
            <IconButton
              icon={<LineArrowLeft />}
              onClick={() => {
                setSelectedBusinessCard(null)
              }}
            />
          }
        />
        <OptionsLock
          options={options}
          selectedOption={selectedBusinessCard}
          setSelectedOption={setSelectedBusinessCard}
          type="businessCard"
        />
        <FormSubmitButton className="w-full" />
      </Form>
    )
  }
  return <BusinessCardList />
}

export default BusinessCard
