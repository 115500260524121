const deleteUserByUid = build =>
  build.mutation({
    query: uid => {
      return {
        url: `/users/${uid}`,
        method: 'DELETE',
      }
    },
    invalidatesTags: [
      'UsersByHashtag',
      'Whitelabel',
      'TeamMembers',
      'UsersByWhitelabel',
    ],
  })

export default deleteUserByUid
