import getFollowers from './getFollowers'
import { api } from '@/Services/api'

export const followersApi = api.injectEndpoints({
  endpoints: build => ({
    getFollowers: getFollowers(build),
  }),
  overrideExisting: true,
})

export const { useGetFollowersQuery } = followersApi
