const reported_page = {
  i_understood: 'I understood',
  been_reported: 'You have been Reported!',
  it_is_important: {
    main_text:
      'It is important that you follow all our rules to continue to use in the best conditions!',
  },
  accept_our_rules:
    'By continuing, you accept our rules of conduct and you thus preserve the quality of our service.',
  good_behavior: {
    be_respectfull: 'Be Respectful',
    Insults_racist:
      'Insults, racist, homophobic, sexist, or any other discriminatory remarks are prohibited.',
    be_honest: 'Be Honest',
    fake_profiles:
      'Fake profiles, photos of celebrities, or images that are not of you are prohibited.',
    be_courteous: 'Be Courteous',
    request_for_personal:
      'Requests for personal information, requests for sexual services, or any other inappropriate behavior are prohibited.',
    be_yourself: 'Be Yourself',
    photo_of_landscapes:
      'Photos of landscapes, flowers, or any other elements that do not represent you are prohibited.',
  },
}

export default reported_page
