import { Config } from '@/Config'
import { useGetWhitelabel, useTheme } from '@/Hooks'
import useCurrentMember from '@/Hooks/useCurrentMember'
import useCurrentUser from '@/Hooks/useCurrentUser'
import useUploadImage from '@/Hooks/useUploadImage'
import {
  useCreateBusinessCardByUidMutation,
  useUpdateBusinessCardByIdMutation,
} from '@/Services/modules/businessCards'
import deepEqual from 'deep-equal'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'

const EditBusinessCardContext = createContext()

const getDefaultBusinessCard = ({ theme, user, logo }) => {
  return {
    id: null,
    template: 'template1',
    color: theme.colors.primary,
    logo: user.logoURL || logo,
    fullName: '',
    job: '',
    businessName: '',
    email: '',
    phone: '',
    address: '',
    addressComplement: '',
    zipCode: '',
    city: '',
    state: '',
    website: '',
  }
}

export const EditBusinessCardProvider = ({ children }) => {
  const { theme } = useTheme()
  const { currentUser } = useCurrentUser()
  const whitelabel = useGetWhitelabel()
  const { currentMember, isMultiMemberEdition } = useCurrentMember()
  const user = currentMember || currentUser
  const defaultBusinessCard = getDefaultBusinessCard({
    theme,
    user,
    logo: whitelabel?.logoSmall,
  })
  const { uploadImage } = useUploadImage()
  const [selectedBusinessCard, setSelectedBusinessCard] = useState(null)
  const [editBusinessCard, setEditBusinessCard] = useState(null)
  const edition = Boolean(selectedBusinessCard?.id)
  const [createBusinessCard] = useCreateBusinessCardByUidMutation()
  const [updateBusinessCardById] = useUpdateBusinessCardByIdMutation()
  const { checkedAllMemberUid } = useSelector(state => state.member)
  const changes = useMemo(() => {
    if (!selectedBusinessCard || !editBusinessCard) {
      return {}
    }
    return Object.entries(editBusinessCard).reduce(
      (acc, [key, value]) =>
        !deepEqual(value, selectedBusinessCard?.[key])
          ? {
              ...acc,
              [key]: typeof value === 'string' ? value.trim() : value,
            }
          : acc,
      {},
    )
  }, [editBusinessCard, selectedBusinessCard])

  const haveChanges = useMemo(() => {
    return Object.keys(changes).length > 0
  }, [changes])

  const addLogoIfExist = useCallback(
    async (data, logo) => {
      if (!logo) {
        return null
      }

      try {
        const { src } = await uploadImage(logo)
        data.logo = src
      } catch (error) {
        // TODO TOAST
        // Toast.show({
        //   type: 'error',
        //   text1: t('toastErrorDefault.error'),
        //   props: {
        //     closable: true,
        //   },
        // })
      }
    },
    [uploadImage],
  )
  const onCreateBusinessCard = useCallback(async () => {
    if (isMultiMemberEdition) {
      await Promise.all(
        checkedAllMemberUid.map(async userUid => {
          await createBusinessCard({
            userId: userUid,
            businessCard: {
              ...defaultBusinessCard,
              ...changes,
              whitelabel: Config.SLUG,
              logo: changes.logo || defaultBusinessCard.logo,
            },
          })
        }),
      )
      return
    }
    await createBusinessCard({
      userId: user.uid,
      businessCard: {
        ...defaultBusinessCard,
        ...changes,
        whitelabel: Config.SLUG,
        logo: changes.logo || defaultBusinessCard.logo,
      },
    })
  }, [
    changes,
    checkedAllMemberUid,
    createBusinessCard,
    defaultBusinessCard,
    isMultiMemberEdition,
    user.uid,
  ])

  const onSubmit = useCallback(async () => {
    try {
      if (!changes) {
        // TODO TOAST
        // Toast.show({
        //   type: 'warning',
        //   text1: t('edit_profil.notif.champ'),
        //   props: {
        //     closable: true,
        //   },
        // })
        return
      }

      await addLogoIfExist(changes, changes.logo)

      if (edition) {
        await updateBusinessCardById({
          cardId: editBusinessCard?.id,
          businessCard: changes,
        })
      }

      if (!edition) {
        await onCreateBusinessCard()
      }
      setSelectedBusinessCard(null)
      // TODO TOAST
      // Toast.show({
      //   type: 'success',
      //   text1: t('edit_profil.notif.succes'),
      //   props: {
      //     closable: true,
      //   },
      // })
    } catch (error) {
      // TODO TOAST
      // Toast.show({
      //   type: 'error',
      //   text1: error.message || t('toastErrorDefault.error'),
      //   props: {
      //     closable: true,
      //   },
      // })
    }
  }, [
    changes,
    addLogoIfExist,
    edition,
    updateBusinessCardById,
    editBusinessCard?.id,
    onCreateBusinessCard,
  ])

  useEffect(() => {
    if (!selectedBusinessCard && editBusinessCard) {
      setEditBusinessCard(null)
    }
    if (editBusinessCard) {
      return
    }

    if (!edition && selectedBusinessCard) {
      setEditBusinessCard(selectedBusinessCard)
      return
    }

    if (!edition && editBusinessCard?.id) {
      setEditBusinessCard(defaultBusinessCard)
      return
    }

    if (edition && selectedBusinessCard?.id !== editBusinessCard?.id) {
      setEditBusinessCard(selectedBusinessCard)
    }
  }, [edition, editBusinessCard, selectedBusinessCard, defaultBusinessCard])

  return (
    <EditBusinessCardContext.Provider
      value={{
        haveChanges,
        onSubmit,
        defaultBusinessCard,
        selectedBusinessCard,
        editBusinessCard,
        setEditBusinessCard,
        setSelectedBusinessCard,
        edition,
        initialLogo: whitelabel?.logoSmall,
      }}
    >
      {children}
    </EditBusinessCardContext.Provider>
  )
}

export function useEditBusinessCard() {
  return useContext(EditBusinessCardContext)
}
