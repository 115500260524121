const profilepage = {
  application_available: 'Application disponible',
  download_app: 'Telecharger',
  title_tab_bottom: 'Profil',
  preview: 'Aperçu',
  my_profile: 'Mon profil',
  at_home_bar: 'à',
  warning_email: "Renvoyer l'e-mail de confirmation",
  advanced_options: 'Options avancées',
  instant_redirection: 'Redirection instantanée',
  activity: 'Activité',
  add_content: 'Ajouter un contenu',
  add_image: 'Ajouter une image',
  multiple_edition: 'Édition multiple',
  contact: {
    tel_option: 'Téléphone',
    email_option: 'E-mail',
    address_option: 'Adresse',
    title_tab: 'Contact',
    top: {
      sentence: 'Bonne journée',
      addcontact: 'Ajouter un nouveau contact',
    },
    bottom: {
      visible_all: 'Visible de tous',
      visible_contact_only: 'Visible seulement par mes contacts',
    },
    // contact_only: {
    //   adresse: 'Mon adresse',
    //   phone_work: 'Téléphone bureau',
    // },
  },
  social: {
    title_tab: 'Social',
    add_social: 'Ajouter un nouveau réseau social',
    add_social2: 'Ajouter un nouveau réseau',
    my_social_network: 'Mes réseaux sociaux',
    search_network: 'Rechercher un nouveau contenu',
  },
  link: {
    add_link: 'Ajouter un nouveau lien',
    my_links: 'Mes liens',
    title_tab: 'Liens',
    video_tab_show_on_profile: 'Afficher sur le profil',
  },
  galerie: {
    title_tab: 'Galerie',
    add_photo: 'Ajouter une nouvelle photo',
    galerie: 'Ma galerie',
    edit_photo: {
      add_hashtag: 'Ajouter un hashtag',
      save: 'Sauvegarder',
    },
    edit_image_panel_title: 'Modifier votre image',
    add_image_panel_title: 'Ajouter une nouvelle image',
  },
}

export default profilepage
