const others = {
  complete_your_welcome_quests: 'Terminez vos quêtes de bienvenue',
  share_business_card: {
    title: 'Partager ma carte',
  },
  scan: {
    waitings: 'Scan en cours ...',
    empty_permissions: 'Permission manquante',
  },
  select_value: 'Sélectionnez une valeur',
  negative_prompt_explanation_page: 'Prompt négatifs',
  see_more: 'Voir plus',
  see_less: 'Voir moins',
  copy_text_area: 'copier',
  delete_all_notifications: 'Supprimer toutes les notifications',
  private_profile: 'Ce profil est privé',
  or_continue_with: 'Ou continuer avec',
  participants_on_this_post: 'participant{{plurial}} sur ce post',
  water: 'Arroser',
  watered: 'Arrosé',
  watered_your_seed: 'a arrosé votre graine',
  watered_the_seed: 'a arrosé la graine',
  no_internet_connection: 'Pas de connexion internet',
  my_budly_profile: 'Mon profil : ',
  end_of_the_list: "Il n'y a plus rien à afficher",
  errors: {
    username_already_used: 'Ce nom d’utilisateur est déjà utilisé',
    invalid_email: 'Email invalide',
    all_fields_required: 'Tous les champs sont obligatoires',
  },
  gallery_of: 'Galerie de {{name}}',
  free: 'Gratuit',
  submit: 'Envoyer',
  cancel: 'Annuler',
  delete: 'Supprimer',
  save: 'Sauvegarder',
  modifications_saved: 'Les modifications ont été sauvegardées !',
}

export default others
