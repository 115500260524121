import React, { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import AccountPanelButton from './Buttons/AccountPanelButton'
import { Button } from './Buttons/Button'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useGetWhitelabel } from '@/Hooks'
import Text from './Texts/Text'
import { useTranslation } from 'react-i18next'

const StyledTopBar = styled.header`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: ${theme.space.m} ${theme.space.l};
    border-bottom: 1px solid ${theme.colors.grey1};
    position: fixed;
    top: 0;
    z-index: 1;
    background-color: ${theme.colors.white};
  `}
`

const Logo = styled.img`
  ${({ theme }) => css`
    width: ${theme.size.m};
    height: ${theme.size.m};
  `}
`

const TopBar = forwardRef((_, ref) => {
  const { pathname } = useLocation()
  const { currentUser } = useSelector(state => state.user)
  const whitelabel = useGetWhitelabel()
  const { t } = useTranslation()

  return (
    <StyledTopBar ref={ref}>
      <Link to="profile" className="flex items-center gap-3">
        <Logo src={whitelabel?.logoSmall} />
        <Text color={whitelabel?.color2}>
          {whitelabel?.whiteLabelName || 'Clink'}
        </Text>
      </Link>

      <div className="flex items-center gap-2">
        {currentUser.admin && (
          <Button
            variant={pathname.includes('admin') ? 'primary' : 'secondary'}
            className="h-12 rounded-[20px]"
            label="Espace Admin"
            type="link"
            to={pathname.includes('admin') ? 'profile' : 'admin'}
          />
        )}
        {(!currentUser.main_uid || currentUser.memberRole === 'admin') && (
          <Button
            variant={pathname.includes('team') ? 'primary' : 'secondary'}
            className="h-12 rounded-[20px]"
            label={t('teams_page.team_space')}
            type="link"
            to={pathname.includes('team') ? 'profile' : 'team'}
          />
        )}
        <AccountPanelButton />
      </div>
    </StyledTopBar>
  )
})

export default TopBar
