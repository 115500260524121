const edit_profil = {
  preview: 'See my profile',
  save: 'Save',
  insight: 'Preview',
  reset: 'Reset',
  title: 'Edit my profile',
  manage_contents: 'Manage my contents',
  notif: {
    succes: 'The information has been saved successfully',
    champ: 'No fields have been modified',
    not_enough_coins: 'You do not have enough coins to make this purchase',
    error: 'An error occured, please try again later',
    interior: 'Interior',
    subtitle_interior:
      'The interior corresponds to the shape of the eyeballs of your QRCode',
    exterior: 'Exterior',
    subtitle_exterior:
      'The exterior corresponds to the shape of the frames of the eyes of your QRCode',
  },
  contents: {
    title: 'Contents',
    subtitle: 'Organize your content and share it from your profile.',
    add_content_panel_titles: {
      contact: 'Add a new contact link',
      link: 'Add a new link',
      social: 'Add a new social media link',
      gallery: 'Add a new image to the gallery',
    },
    edit_content_panel_titles: {
      contact: 'Edit a contact link',
      link: 'Edit a link',
      social: 'Edit a social media link',
      gallery: 'Edit an image in the gallery',
    },
  },
  qr_code: {
    title: 'QR Code',
    subtitle:
      'Customize your QRCode to make it unique and recognizable by your network.',
    pay_and_save: 'To see the selected changes pay and save your basket.',
  },
  informations: {
    title: 'Your informations',
    subtitle: 'All information is visible to users.',
    modify_photos: 'Edit my photos',
    add_photos: 'Add photos',
    appearance: 'Appearance',
    add_pp: 'Add Profile Picture',
    add_bg: 'Add cover photo',
    add_logo: 'Add company logo',

    my_infos: {
      title: 'Information',
      name: 'First Name',
      name_placeholder: 'Your first name',
      surname: 'Last Name',
      surname_placeholder: 'Your last name',
      job: 'Job',
      job_placeholder: 'Your job',
      enterprise: 'Enterprise',
      enterprise_placeholder: 'Your enterprise',
      address: 'Address',
      address_placeholder: 'Your address',
      description: 'Description',
      description_placeholder: 'Your description',
    },

    hashtags: {
      title: 'Your hashtags',
      add_hashtags: 'Add a hashtag',
      error1: 'Max 3 hashtags',
      error2: 'Character minimum 3 and maximum 12',
      error3: 'Forbidden hashtag repetition',
    },

    advanced_options: {
      private_mode: 'Private mode',
      title: 'Advanced options',
      business_profile: 'Business profile',
    },

    save: 'Save',
    insight: 'Preview',
  },

  appearance: {
    title: 'Appearance',
    subtitle: 'Stand out by personalizing your profile.',
    color_theme: {
      title: 'Theme color',
      color: 'Colors',
      perso: 'perso',
      grey: 'grey',
      blue: 'blue',
      red: 'red',
      yellow: 'yellow',
      green: 'green',
      violet: 'purple',
      orange: 'orange',
      color_perso: 'Custom Color',
      restart: 'Reset',
      preview_btn: 'Preview',
      default: 'default',
    },
    shape_avatar: {
      title: 'Avatar shape',
      shapes: 'Shapes',
      motif: 'Pattern',
      motif_subtitle: 'Shapes and colors of the eye frames of your QRCode',
      bg: 'Background',
      eye: 'Eye',
    },
    btn_style: {
      title: 'Buttons style',
      shape: 'Shape',
      styles: 'Style',
      show_background: 'Show background',
      show_outline: 'Show outline',
      show_content_as_list: 'Show content as list',
      outline_color: 'Outline color',
    },
    hide_informations: {
      title: 'Information',
      view_profil: 'Hide views on your profile',
      hide_btn_contact: 'Hide "Add to contact" button',
      hide_contact: 'Hide your contacts',
      hide_followers: 'Hide your followers',
      hide_following: 'Hide your following',
      hide_contents_subtitle: 'Hide contents subtitle',
      hide_sharing_btn: 'Hide sharing buttons on my content',
      show_certification: 'Show certification',
    },
    replica_profil: {
      title: 'Replicate a profile',
      replica: 'Replicate',
      url_profile: 'Profile URL or username',
      dont_forget_to_save:
        "A profile has been replicated, don't forget to save",
    },
    text_style: {
      title: 'Style of text',
    },
    social_color: {
      title: 'Color social media',
    },
    logo: {
      preview_text: 'Edit the logo',
      label_slider: 'Size',
    },
    buy_items_modal: {
      cart: 'Cart',
      title: 'You are about to spend {{totalPrice}} coins.',
      pay_and_save_btn: 'Pay and save',
      item_descriptions: {
        'background-color': 'Custom theme color',
        'shape-avatar': 'Personalised avatar shape',
        btnShape: 'Personalised button shape',
        btnOutlineColor: 'Personalised outline color',
        btnAddIsHide: "Hide button 'Add'",
        duplicateProfile: 'Duplicating a profile',
        hideContentsSubtitle: 'Hide content subtitle',
        hideFollowers: 'Hide followers',
        hideFollowing: 'Hide following',
        hideSharingBtnContent: 'Hide sharing buttons',
        banner: 'Banner',
        isCertified: 'Certification',
        'card-color': 'Card color',
        'qr-background-color': 'QRCode background color',
        'qr-pattern-shape': 'QRCode pattern shape',
        'qr-eye-ball-shape': 'QRCode eye ball shape',
        'qr-eye-frame-shape': 'QRCode eye frame shape',
        'card-template': 'Card template',
        'qr-background-color-custom': 'Custom background color for QR code',
        'qr-background-color-custom-eyes-interior':
          "Custom color of the QR Code's eye interiors",
        'qr-background-color-custom-eyes-outside':
          "Custom color of the QR Code's eye exteriors",
        'qr-background-color-shape-custom':
          'Custom background color of QR Code pattern',
      },
    },
    acquired: 'Acquired',
    used: 'Used',
    select_color: 'Select a color',
  },
}

export default edit_profil
