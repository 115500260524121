const buyCosmetic = build =>
  build.mutation({
    query: ({ uid, itemIds, buyerUID = '' }) => {
      return {
        url: `/cosmetics/buy/${uid}${buyerUID ? `?buyerUID=${buyerUID}` : ''}`,
        method: 'POST',
        body: { itemIds },
      }
    },
    invalidatesTags: ['Cosmetics', 'AvailableResources'],
  })

export default buyCosmetic
