import React, { useCallback, useEffect, useMemo } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { ProfileProvider } from '../../Components/Profile/Contexts/ProfileContext'
import { ModalDownloadAppProvider } from '../../Components/Profile/Contexts/ModalDownloadAppContext'
import Profile from '@/Components/Profile/Profile'
import { useEditCurrentUser } from '@/Components/Dashboard/Contexts/EditProfileContext'
import { useGetCurrentProfileOrRedirectQuery } from '@/Services/modules/users'
import { useIntercom } from 'react-use-intercom'
import { useGetFollowersQuery } from '@/Services/modules/followers'
import useCurrentUser from '@/Hooks/useCurrentUser'
import useCurrentMember from '@/Hooks/useCurrentMember'
import { useGetWhitelabel } from '@/Hooks'

const ProfileContainer = props => {
  const { slug } = useParams()
  const { shutdown } = useIntercom()
  const whitelabel = useGetWhitelabel()
  const {
    data: profileBySlug,
    isLoading,
    error,
  } = useGetCurrentProfileOrRedirectQuery(
    { slug, whitelabel: whitelabel?.slug },
    {
      skip: !slug,
    },
  )
  const { currentUser } = useCurrentUser()
  const { currentMember } = useCurrentMember()
  const user = currentMember || currentUser
  const editCurrentUserContext = useEditCurrentUser()
  const { data: followers } = useGetFollowersQuery(profileBySlug?.uid, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
    skip: !profileBySlug?.uid,
  })

  const fastLink = useMemo(
    () => profileBySlug?.links?.find(link => link.isFastLink),
    [profileBySlug],
  )

  useEffect(() => {
    if (fastLink) {
      window.location.href = fastLink.url
    }
  }, [fastLink])

  const setCurrentProfile = useCallback(() => {
    //TODO
  }, [])

  const isFollow = useMemo(() => {
    if (!user?.uid) {
      return false
    }

    return followers?.some(follower => follower.uid === user.uid)
  }, [followers, user?.uid])

  const config = useMemo(() => {
    const profile =
      profileBySlug ||
      (editCurrentUserContext
        ? {
            ...editCurrentUserContext?.editCurrentUser,
            ...(user ? { gallery: user?.gallery } : {}),
          }
        : null)
    if (!profile) {
      return null
    }

    return {
      profilePublic: slug !== profile?.id && !profile?.ids?.includes(user?.id),
      profile,
      setCurrentProfile,
      canSeeAllContacts:
        slug === profile?.id || profile?.ids?.includes(user?.id),
      isFollow,
      edition: Boolean(editCurrentUserContext),
    }
  }, [
    profileBySlug,
    setCurrentProfile,
    user,
    editCurrentUserContext,
    isFollow,
    slug,
  ])

  useEffect(() => {
    if (profileBySlug) {
      shutdown()
    }
  }, [shutdown, profileBySlug])

  if (fastLink) {
    return null
  }

  if (error?.data?.redirection)
    return <Navigate to={error?.data?.redirection} />

  if (!config || isLoading) return null
  if (isLoading) return null

  return (
    <ProfileProvider config={config}>
      <ModalDownloadAppProvider>
        <Profile {...props} />
      </ModalDownloadAppProvider>
    </ProfileProvider>
  )
}

export default ProfileContainer
