const signin = {
  title_page: 'Home',
  log_in: 'Log in',
  welcome_back: 'Welcome back to the {{whitelabelName}} community!',
  do_not_have: "Don't have an account yet?",
  register: 'Register',
  home: {
    title: 'Your digital profile',
    actions: {
      signin: 'Sign in',
      scan: 'Scan a product',
    },
  },
  inputs: {
    label_email: 'Email Address',
    email: 'Your email',
    label_password: 'Password',
    password: 'Your password',
    please_complete: 'Please fill in all the fields of the form.',
  },
  others: {
    forgot: 'Forgot your password ?',
    continue: 'Continue',
    welcome: 'Welcome back !',
    report_problem: 'Report a problem',
    rgpd_request: 'GDPR Request',
    faqs: 'FAQs',
    remember_me: 'Remember me',
    password_reset_link_sent:
      'The reset link has been sent to your email address.',
  },
  img: {
    welcome_to_budly: 'Welcome to {{whitelabelName}}',
    create_your_digital:
      'Create your digital profile according to your desires and your image',
  },
}

export default signin
