import Mention from './Mention'
import Url from './Url'
import { useTheme } from '@/Hooks'
import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Text from '../../Texts/Text'

const POSSIBLE_INTERACTIONS = [Mention, Url]

const InteractiveText = ({ children = '', numberOfLines, ...props }) => {
  const formattedText = useMemo(() => {
    const partsWithSpaceAndLineBreak = children.match(/[^\s\n]+|\s|\n/g) || []
    return partsWithSpaceAndLineBreak.map((word, index) => {
      const interaction = POSSIBLE_INTERACTIONS.find(i => i.check(word))
      if (interaction) {
        return <interaction.component key={index}>{word}</interaction.component>
      }
      return word
    })
  }, [children])
  const [linesLength, setLinesLength] = useState(null)
  const [showAll, setShowAll] = useState(false)
  const theme = useTheme()
  const { t } = useTranslation()
  const onTextLayout = useCallback(e => {
    setLinesLength(e.nativeEvent.lines.length)
  }, [])
  const hasMore = useMemo(() => {
    return linesLength > numberOfLines
  }, [linesLength, numberOfLines])
  const text = formattedText.map(word => {
    return typeof word === 'string' ? `${word}` : <>{word} </>
  })
  // return (
  //   <div className="bg-red">
  //     {linesLength === null ? (
  //       <Text className="opacity-0" onTextLayout={onTextLayout}>
  //         {text}
  //       </Text>
  //     ) : (
  //       <Text numberOfLines={showAll ? undefined : numberOfLines}>{text}</Text>
  //     )}
  //     {hasMore && (
  //       <TouchableOpacity
  //         className="py-2"
  //         onClick={() => setShowAll(current => !current)}
  //       >
  //         <Text color={theme.colors.primary}>
  //           {showAll ? t('see_less') : t('see_more')}
  //         </Text>
  //       </TouchableOpacity>
  //     )}
  //   </div>
  // )
  return <Text {...props}>{text}</Text>
}

export default InteractiveText
