import * as React from 'react'
const SvgBag2 = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 25})`}>
      <path
        stroke="#58637D"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="m9.2 7.738-.201.479a4.13 4.13 0 0 0-.234 2.448 3.14 3.14 0 0 0 2.52 2.445l.11.02c.4.071.81.071 1.21 0l.11-.02a3.14 3.14 0 0 0 2.52-2.445 4.13 4.13 0 0 0-.234-2.448l-.201-.48"
      />
      <path
        stroke="#58637D"
        strokeWidth={1.5}
        d="M20.22 15.88a6.78 6.78 0 0 1-5.017 5.083c-2.101.508-4.305.508-6.405 0a6.78 6.78 0 0 1-5.019-5.082c-.509-2.24-.385-4.578.358-6.752l.11-.323a7 7 0 0 1 5.347-4.62l.68-.127a9.4 9.4 0 0 1 3.452 0l.68.127a7 7 0 0 1 5.347 4.62l.11.323c.744 2.174.867 4.512.358 6.752Z"
      />
    </g>
  </svg>
)
export default SvgBag2
