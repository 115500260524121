import { useSelector } from 'react-redux'
import { useCosmetics } from '../../Contexts/CosmeticsContext'
import { useState } from 'react'
import Text from '@/Components/Texts/Text'
import { useTranslation } from 'react-i18next'
import { useEditCurrentUser } from '../../Contexts/EditProfileContext'
import { useTheme } from '@/Hooks'
import EyesQrCodeBloc from './components/EyesQrCodeBloc'

const EyesQrCode = ({ route }) => {
  const { t } = useTranslation()
  const { theme } = useTheme()
  const { currentUser } = useSelector(state => state.user)
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser()
  const cosmetics = useCosmetics()

  const handleChanges = (key, value) => {
    let newEditCurrentUser = JSON.parse(JSON.stringify(editCurrentUser))
    newEditCurrentUser.qrcode.attributes[key] = value
    setEditCurrentUser(newEditCurrentUser)
  }

  return (
    <div className="w-full flex flex-col">
      <Text className="mb-2">{t('edit_profil.notif.interior')}</Text>
      <Text regular color={theme.colors.grey2}>
        {t('edit_profil.notif.subtitle_interior')}
      </Text>
      <EyesQrCodeBloc
        shape={editCurrentUser.qrcode?.attributes.eyeBallShape}
        setShape={shape => handleChanges('eyeBallShape', shape)}
        color={editCurrentUser.qrcode?.attributes.eyeBallColor}
        setColor={color => handleChanges('eyeBallColor', color)}
        usedShape={currentUser.qrcode?.attributes.eyeBallShape}
        cosmeticShapes={cosmetics['qr-eye-ball-shape']}
        excludes={['kite']}
      />
      <Text className="mb-2">{t('edit_profil.notif.exterior')}</Text>
      <Text regular color={theme.colors.grey2}>
        {t('edit_profil.notif.subtitle_exterior')}
      </Text>
      <EyesQrCodeBloc
        shape={editCurrentUser.qrcode?.attributes.eyeFrameShape}
        setShape={shape => handleChanges('eyeFrameShape', shape)}
        color={editCurrentUser.qrcode?.attributes.eyeFrameColor}
        setColor={color => handleChanges('eyeFrameColor', color)}
        usedShape={currentUser.qrcode?.attributes.eyeFrameShape}
        cosmeticShapes={cosmetics['qr-eye-frame-shape']}
        excludes={['left-diamond', 'right-diamond', 'kite']}
      />
    </div>
  )
}

export default EyesQrCode
