import { t } from 'i18next'
import React from 'react'
import styled, { css } from 'styled-components'
import Text from '../Texts/Text'
import { useTheme } from '@/Hooks'
import { backgroundIsLight } from '@/Utils/transformColor'

const StyledInterest = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    padding: ${theme.space.xs} ${theme.space.s};
    border-radius: ${theme.borderRadius.m};
    background-color: ${props =>
      props.isSelected ? theme.colors.primary : theme.colors.white};
    border: 1px solid
      ${props => (props.isSelected ? theme.colors.primary : theme.colors.grey1)};
    color: ${props =>
      props.isSelected ? theme.colors.white : theme.colors.grey2};
    margin-right: ${theme.space.xs};
    margin-bottom: ${theme.space.xs};
    display: flex;
    flex-direction: row;
    cursor: pointer;

    @media screen and (max-height: 732px) {
      padding: ${theme.space.xs} ${theme.space.s};
    }
  `}
`

const Interest = ({ interest, tags, onChange, selected }) => {
  const { theme, isDarkMode } = useTheme()
  const onClick = () => {
    if (selected) {
      onChange(tags.filter(value => value !== interest.value))
      return
    }
    if (tags.length >= 3) {
      return
    }
    onChange([...tags, interest.value])
  }
  return (
    <StyledInterest onClick={onClick} isSelected={selected}>
      {/* <Text>{interest.icon}</Text> */}
      <Text
        isSelected={selected}
        color={selected ? '#FFF' : theme.colors.grey3}
        className="tablet:text-xs"
      >
        {t(`onboarding.tags.${interest.value}`)}
      </Text>
    </StyledInterest>
  )
}

export default Interest
