const notifications = {
  notifications: 'Notificationes',
  all: {
    title: 'Todas las notificaciones',
  },
  daily_quests_reminder: {
    title: 'Misiones diarias',
    message: 'Completa todas tus misiones diarias',
  },
  daily_rewards_reminder: {
    title: 'Recompensas diarias',
    message: 'Reclama tus recompensas diarias',
  },
  follow_request: {
    title: 'Solicitud de seguimiento',
    message: 'te ha enviado una solicitud de seguimiento',
  },
  follow_response: {
    title: 'Respuesta de seguimiento',
    message: 'ha {{response}} tu solicitud de seguimiento',
  },
  mention_in_image: {
    title: 'Mención en imagen',
    message: 'te ha mencionado en una imagen',
  },
  mention_in_post: {
    title: 'Mención en publicación',
    message: 'te ha mencionado en una publicación',
  },
  mention_in_profile: {
    title: 'Mención en perfil',
    message: 'te ha mencionado en un perfil',
  },
  new_follow: {
    title: 'Nuevo seguidor',
    message: 'te ha seguido',
  },
  new_image: {
    title: 'Nueva imagen',
    message: 'ha publicado una nueva imagen',
  },
  new_post: {
    title: 'Nueva publicación',
    message: 'ha publicado una nueva publicación',
  },
  new_watering: {
    title: 'Nuevo riego',
    message: 'ha regado tu semilla',
  },
  welcome_quests_reminder: {
    title: 'Misiones de bienvenida',
    message: 'Completa todas tus misiones de bienvenida',
  },
  sponsor_validated: {
    title: 'Patrocinador validado',
    message: 'ha validado todas tus misiones de bienvenida',
  },
  mall_is_open: {
    title: 'Mall está disponible!',
    message: '¡Ven y disfruta de nuestras promociones exclusivas!',
  },
}

export default notifications
