const sendWelcomeEmail = build =>
  build.mutation({
    query: userId => {
      return {
        url: `/users/verify/${userId}/send-welcome-email`,
        method: 'POST',
      }
    },
  })

export default sendWelcomeEmail
