import * as React from 'react'
const SvgLineFocusGreen = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 21})`}>
      <path
        stroke="#58637D"
        strokeWidth={1.5}
        d="M10 15.238a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z"
      />
      <path fill="#58637D" d="M10 12.238a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3" />
      <path
        stroke="#58637D"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M2.5 5.738s3.75-5.75 10.25-3.5 6.25 8.75 6.25 8.75m-1.5 4.75s-3 5.5-9.75 3.75S1 10.738 1 10.738v8m18-16v8"
      />
    </g>
  </svg>
)
export default SvgLineFocusGreen
