import ColorPickerField from '@/Components/ColorPickerField'
import QrCodeShapeChoice from './QrCodeShapeChoice'
import { useTranslation } from 'react-i18next'
import Text from '@/Components/Texts/Text'

const EyesQrCodeBloc = ({
  color,
  setColor,
  usedShape,
  shape,
  setShape,
  excludes,
  cosmeticShapes,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <Text className="my-3">
        {t('edit_profil.appearance.shape_avatar.shapes')}
      </Text>
      <QrCodeShapeChoice
        background={color}
        onChange={setShape}
        selectedShape={shape}
        usedShape={usedShape}
        excludes={excludes}
        cosmetics={cosmeticShapes}
      />
      <Text className="my-3">
        {t('edit_profil.appearance.color_theme.color')}
      </Text>
      <div className="mb-10 tablet:mb-5">
        <ColorPickerField value={color} onChange={setColor} />
      </div>
    </>
  )
}

export default EyesQrCodeBloc
