export const colors = {
  primary: '#58637D',
  secondary: '#F4F4F4',
  primaryBG: '#EBF8EB',
  tertiary: '#FFFFFF',
  white: '#FFFFFF',
  black: '#212121',
  grey1: '#F4F4F4',
  grey2: '#8E8E95',
  grey3: '#202437',
  red: '#FF006D',
  green: '#008000',
  green1: '#136D27',
  warning: '#FFA500',
  info: '#1900ff',
  success: '#00c066',
}

export const lightColors = {
  primary: '#58637D',
  secondary: '#F4F4F4',
  tertiary: '#FFFFFF',
  white: '#FFFFFF',
  black: '#000000',
  grey1: '#F4F4F4',
  grey2: '#8E8E95',
  grey3: '#202437',
  red: '#FF006D',
  green: '#008000',
  green1: '#136D27',
  warning: '#FFA500',
  info: '#1900ff',
  success: '#00c066',
  lightBlue: '#6BDCEC',
  textLightBlue: '#004F5E',
  pink: '#E6ADE9',
  textPink: '#4A2C6B',
  yellow: '#FFE77A',
  textYellow: '#816214',
}

export const darkColors = {
  primary: '#58637D',
  secondary: '#2e3745',
  tertiary: '#1b212a',
  white: '#202437',
  black: '#ffffff',
  grey1: '#2e3745',
  grey2: '#556174',
  grey3: '#7f8ca0',
  red: '#FF006D',
  green: '#008000',
  green1: '#136D27',
  warning: '#FFA500',
  info: '#1900ff',
  success: '#00c066',
  lightBlue: '#6BDCEC',
  textLightBlue: '#004F5E',
  pink: '#E6ADE9',
  textPink: '#4A2C6B',
  yellow: '#FFE77A',
  textYellow: '#816214',
}

const theme = {
  colors: lightColors,
  themeName: 'light',
  fontSize: {
    xxs: '10px',
    xs: '12px',
    s: '14px',
    std: '16px',
    m: '18px',
    l: '24px',
    xl: '30px',
    xxl2: '44px',
    xxl3: '56px',
    xxl4: '64px',
    xxl5: '72px',
  },
  fontWeight: {
    normal: 'normal',
    semibold: '500',
    bold: 'bold',
    lighter: 'lighter',
  },
  space: {
    none: '0px',
    xxs: '4px',
    xs: '8px',
    s: '10px',
    std: '12px',
    m: '16px',
    l: '24px',
    xl: '32px',
    xxl: '40px',
    xxl2: '48px',
    xxl3: '72px',
    auto: 'auto',
  },
  size: {
    xxs: '12px',
    xs: '16px',
    s: '24px',
    s2: '32px',
    std: '40px',
    m: '48px',
    m2: '64px',
    l: '72px',
    l2: '84px',
    xl: '96px',
    xl2: '104px',
    xl3: '124px',
    fill: '100%',
  },
  borderRadius: {
    xxs: '4px',
    xs: '8px',
    s: '12px',
    m: '16px',
    l: '24px',
    xl: '32px',
    xxl: '44px',
  },
  layouts: {
    colCenter: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    col: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    row: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
    },
  },
  wrappers: {
    rowCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}

export default theme
