import { setWhitelabelSlug } from '@/Store/Whitelabel'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'

export const useGetWhitelabelBySlugQuery = () => {
  const [searchParams] = useSearchParams()
  const { whitelabelSlug: paramWhitelabelSlug } = useParams()
  const { slug } = useSelector(state => state.whitelabel)
  const { currentUser } = useSelector(state => state.user)
  const whitelabelSlug =
    currentUser?.whiteLabel ||
    searchParams.get('w') ||
    paramWhitelabelSlug ||
    slug

  const dispatch = useDispatch()

  useEffect(() => {
    if (whitelabelSlug) {
      dispatch(setWhitelabelSlug(whitelabelSlug))
    }
  }, [dispatch, whitelabelSlug])

  return null
}
