import { Button } from '@/Components/Buttons/Button'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { onRedirectShop } from './functions'

export const RedirectShopButton = ({ user }) => {
  const [loading, setLoading] = useState(false)

  const handleRedirectShop = async () => {
    setLoading(true)
    try {
      const res = await onRedirectShop({ user })
      if (res.status !== 200) {
        throw new Error('Une erreur est survenue')
      }

      window.open(res.data, '_blank').focus()
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }
  if (!user?.shopRocket?.store_id) {
    return null
  }

  return (
    <Button
      label="Modifier mon shop"
      onClick={handleRedirectShop}
      className="w-full"
      loading={loading}
    />
  )
}
