const createMembers = build =>
  build.mutation({
    query: ({ whitelabelSlug, data }) => {
      return {
        url: `/members/${whitelabelSlug}/multiple`,
        method: 'POST',
        body: data,
      }
    },
    invalidatesTags: ['TeamMembers'],
  })

export default createMembers
