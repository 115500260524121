import user from './User'
import member from './Member'
import whitelabel from './Whitelabel'
import language from './Language'
import theme from './Theme'
import { api } from '@/Services/api'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import storage from 'redux-persist/lib/storage'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'

const reducers = combineReducers({
  user,
  member,
  whitelabel,
  language,
  api: api.reducer,
  theme,
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['whitelabel', 'theme', 'language'],
}

const rootReducer = (state, action) => {
  if (action.type === 'RESET') {
    state = undefined
  }
  return reducers(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(api.middleware)
    return middlewares
  },
})

const persistor = persistStore(store)

setupListeners(store.dispatch)

export { store, persistor }
