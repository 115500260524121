const activitypage = {
  tab_games: 'Juegos',
  title_tab_bottom: 'Jugar',
  quests: 'Misiones',
  games: 'Juegos',
  leaderboard: 'Tabla de clasificación',
  experience: 'Experiencia',
  planted: 'Plantado',
  planted_plural: 'Plantados',
  Streak: 'Racha',
  streak_unit: 'día consecutivo',
  streak_unit_plural: 'días consecutivos',
  plantations: 'Plantaciones',
  claim_your_daily_rewards: 'Reclama sus recursos diarios',
  claim_your_daily_reward_female: 'Reclama tu {{ressource}} diaria',
  claim_your_daily_reward_male: 'Reclama tu {{ressource}} diario',
  claim_multi: 'Reclamar mis recursos',
  claim: 'Reclamar mi {{resource}}',
  claimed: 'Próxima {{resource}} en ',
  claimed_multi: 'Reclamar mis recursos en',
  come_every_days: 'Vuelve todos los días para reclamar 1 {{ressource}} gratis',
  come_every_days_multi:
    'Vuelva cada día para recuperar sus recursos diarios de forma gratuita',
  my_daily_quests: 'Mis misiones diarias',
  my_permanent_quests: 'Mis misiones permanentes',
  welcome_quests: 'Misiones de bienvenida',
  remaining: 'restantes',
  seed: 'semilla',
  exp: 'exp',
  drop: 'gota',
  coin: 'moneda',
  ticket: 'ticket',
  level_short: 'niv',
  plant_trees: 'Plantar tus árboles',
  add_seeds_to_plant_trees: 'Añadir semillas para plantar un árbol',
  add_seeds: 'Añadir {{quantity}} semillas',
  plant_your_tree: 'Planta tu árbol',
  complete_welcome_quests_to_unlock:
    'Completa las misiones de bienvenida para desbloquear las',
  daily_quests: 'misiones diarias',
  permanent_quests: 'misiones permanentes',
  planted_trees: 'árbol{{es}} plantado{{plurial}}',
  planted_trees_subtitle: 'Encuentra todos tus árboles plantados.',
  planted_on: 'Plantado en',
  participants_number: 'participantes',
  seeds_number: 'semillas',
  drops_number: 'gotas',
  planted_by_you: 'Por ti',
  user_plantations: 'Las plantaciones de {{userName}}',
  modalSelectResourceQuantity: {
    title: 'Seleccione la cantidad de su recurso',
    subtitle: 'Elige cuánto recurso quieres gastar.',
  },
  quantity: 'Cantitad',
  collective: 'Colectivo ({{quantity}})',
  individual: 'Individual ({{quantity}})',
  step_history: 'Historia de los pasos',
  step_toasters: {
    info: {
      text1: 'App necesita acceder a tus datos de salud',
      text2: 'Actívalo en tus ajustes',
    },
    error: {
      text1: 'Error al obtener los datos de pasos',
      text2: 'Por favor, inténtalo de nuevo más tarde',
    },
    warning: {
      text1: 'No se encontraron pasos',
      text2:
        'Por favor, asegúrate de que tienes datos de pasos o permisos habilitados',
    },
    banner: {
      title: 'Debe tener la aplicación {{appName}} y habilitar los permisos',
      subtitle:
        'Haga clic aquí para descargar {{appName}} y luego vuelva para habilitar los permisos',
    },
  },
}

export default activitypage
