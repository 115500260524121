const tutorialModal = {
  water_Plant_your_trees: 'Arrosez et plantez vos arbres',
  plant_trees:
    'Plantez des arbres avec la communauté ou seul pour augmenter votre impact éco-responsable.',
  your_games: 'Vos jeux et classements',
  collect_resources:
    'Récupérez des ressources quotidiennement grâce aux quêtes, mini-jeux et casino.',
  budly_actions: 'Les activités',
  publish_post_share:
    'Récupérez vos ressources, faites vos quêtes, plantez et marchez pour gagner des récompenses.',
  exclusive_promotions: 'Des promotions exclusives',
  find_exclusive_promotions:
    'Retrouvez des promotions exclusives sur une multitudes de produits.',
  your_profile: 'Votre profil',
  your_profile_is_your:
    'Votre profil est votre carte de visite. Retrouvez toutes vos informations au même endroit.',
  personalize_your_profile: 'Personnalisez votre profil',
  change_the_appearance:
    'Modifiez l’apparence de votre profil et ajoutez des nouveaux contenus sur vous.',
  done: 'Terminer',
  next: 'Suivant',
}

export default tutorialModal
