import getWhitelabelBySlug from './getWhitelabelBySlug'
import getWhitelabelBySlugAndDomain from './getWhitelabelBySlugAndDomain'
import updateWhitelabelBySlug from './updateWhitelabelBySlug'
import { api } from '@/Services/api'

export const whitelabelApi = api.injectEndpoints({
  endpoints: build => ({
    getWhitelabelBySlugAndDomain: getWhitelabelBySlugAndDomain(build),
    getWhitelabelBySlug: getWhitelabelBySlug(build),
    updateWhitelabelBySlug: updateWhitelabelBySlug(build),
  }),
  overrideExisting: true,
})

export const {
  useGetWhitelabelBySlugQuery,
  useUpdateWhitelabelBySlugMutation,
  useGetWhitelabelBySlugAndDomainQuery,
} = whitelabelApi
