const putCurrentUser = build =>
  build.mutation({
    query: ({ body, userId }) => {
      return {
        url: `/users/${userId}`,
        method: 'PUT',
        body,
      }
    },
    invalidatesTags: [
      'UsersByHashtag',
      'Quests',
      'TeamMembers',
      'UsersByWhitelabel',
    ],
  })

export default putCurrentUser
