const games = {
  play: 'Jugar',
  score: 'Puntuación',
  point: 'punto',
  the: 'el',
  game_historical: 'Historial de sus partidas',
  gain_per_game: 'Ganancias por partida',
  cost_per_game: 'Costos por partida',
  final_score: 'Puntuación final',
  game_over: '¿Fin del juego?',
  game_over2: 'Terminado',
  you_are_about_to_leave:
    'Estás a punto de abandonar el juego actual.\nTu puntuación y tus ganancias se perderán',
  leave: 'Salir',
  yes: 'Sí',
  no: 'No',
  no_score_found: 'Ningún resultado encontrado para este juego.',
  no_ranking: 'Ningún ranking encontrado.',
  out_ranking: 'No formas parte del top 100.',
  no_ranking_subtitle: 'Sea en el primero en puntuar en este juego!',
  you: 'Tú',
  continue: 'Continuar',
  experience_gained: 'Experiencia ganada',
  rewards: 'Recompensas',
  volume: 'Volumen',
  sound_effects: 'Efectos de sonido',
  music: 'Música',
  replay: 'Reiniciar',
  tired_companion: 'Compañero cansado',
  too_tired_to_double:
    'Parece que tu compañero está demasiado cansado para seguir duplicando tus ganancias.',
  replay_without_companion: '¿Quieres jugar de nuevo sin él?',
  no_ticket: 'Sin boleto',
  you_do_not_have_any_tickets: 'No tienes ningún boleto en tu posesión.',
  get_in_shop: 'Puedes obtenerlos inmediatamente en la tienda.',
  new_game: '¿Nuevo juego?',
  you_will_use_resources: 'Al reiniciar un juego, usarás tus recursos:',
  vitality_of_companion: '25% de vitalidad de tu compañero',
  ticket: 'boleto',
  remaining: 'Intentos',
  level: 'Nivel',
  remaining_time: 'Tiempo',
  king_budlies: {
    description:
      'Obtén la mejor puntuación fusionando los Budlies entre sí! ¡Se cuenta que algunos han invocado al rey!',
  },
  find_budlies: {
    description:
      'Pon a prueba tus habilidades de observación y memorización siguiendo los caminos de Budlies. Estas adorables criaturas se esconden entre las cajas y depende de ti reconstruir su camino original. Cada nivel presenta un desafío único, con recorridos cada vez más complejos para reconstruir. ¿Podrás aceptar el desafío y convertirte en un verdadero detective de Budlie?',
  },
  budmories: {
    description:
      '¡Pon a prueba tu memoria descubriendo las parejas iguales! ¿Te atreves a aceptar el desafío y convertirte en el maestro de Budmories?',
  },
  game_paid_success: 'Juego pagado con éxito',
  subtitle_welcome_quests_banner: 'Para jugar minijuegos',
  subtitle_welcome_quests_banner_business: 'Para cambiar a un perfil comercial',
  subtitle_welcome_quests_banner_post: 'Para crear una publicación',
  subtitle_welcome_quests_banner_watered: 'Para regar una publicación',
  subtitle_welcome_quests_banner_profile: 'Para interactuar con un perfil',
  cumulated_rewards: 'Recompesas acumuladas',
  seeds_rewarded: 'Semillas obtenidas',
  drops_rewarded: 'Gotas obtenidas',
  coins_rewarded: 'Monedas obtenidas',
  ticket_rewarded: 'Boletos obtenidas',
  no_lives_left: 'No quedan vidas',
  spend_coins_to_revive:
    'Para continuar tu juego, debes gastar monedas para recuperar 1 vida.',
  one_new_life: '1 nueva vida',
}

export default games
