import React from 'react'
import styled, { css } from 'styled-components'
import { transformColor } from '@/Utils/transformColor'

const Loader = styled.div`
  ${({ colorBgCustom }) =>
    colorBgCustom &&
    css`
      border-color: ${({ theme, color }) =>
          color || transformColor(theme[colorBgCustom])}
        transparent transparent transparent !important;
      div {
        border-color: ${({ theme, color }) =>
            color || transformColor(theme[colorBgCustom])}
          transparent transparent transparent !important;
      }
    `}
`

export const LoaderButton = props => {
  return (
    <Loader {...props} className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </Loader>
  )
}
