const signin = {
  title_page: 'Accueil',
  log_in: 'Connectez vous',
  welcome_back: 'Bon retour dans la communauté {{whitelabelName}} !',
  do_not_have: 'Vous n’avez pas encore de compte ?',
  register: 'S’inscrire',
  home: {
    title: 'Votre profil digital',
    actions: {
      signin: 'Se connecter',
      scan: 'Scanner un produit',
    },
  },
  inputs: {
    label_email: 'Adresse email',
    email: 'Votre e-mail',
    label_password: 'Mot de passe',
    password: 'Votre mot de passe',
    please_complete: 'Veuillez remplir tous les champs du formulaire',
  },
  others: {
    forgot: 'Mot de passe oublié ?',
    continue: 'Continuer',
    welcome: 'Bienvenue !',
    report_problem: 'Reporter un problème',
    rgpd_request: 'Requête RGPD',
    faqs: 'FAQs',
    remember_me: 'Se souvenir de moi',
    password_reset_link_sent:
      'Le lien de réinitialisation a été envoyé à votre adresse e-mail.',
  },
  img: {
    welcome_to_budly: 'Bienvenue sur {{whitelabelName}}',
    create_your_digital:
      'Créez votre profil digital selon vos envies et à votre image',
  },
}

export default signin
