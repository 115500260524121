const getQueryParamValue = (url, paramName) => {
  const queryString = url.split('?')[1]
  if (!queryString) {
    return null
  }

  const params = queryString.split('&')
  for (const param of params) {
    const [key, value] = param.split('=')
    if (key === paramName) {
      return decodeURIComponent(value)
    }
  }

  return null // Parameter not found
}

export const getEmbedByPlatform = (platform, value) => {
  if (platform === 'spotify') {
    const splittedUrl = value.split('/')
    const musicId = splittedUrl[splittedUrl.length - 1]
    return `https://open.spotify.com/embed/track/${musicId}`
  }
  if (platform === 'appleMusic') {
    const path = value.split('//')[1]
    return `https://embed.${path}`
  }
  if (platform === 'youtube') {
    const video = value.includes('v=')
      ? getQueryParamValue(value, 'v')
      : value.split('/')[value.split('/').length - 1]
    return `https://www.youtube.com/embed/${video}`
  }
  if (platform === 'facebook') {
    return `https://www.facebook.com/plugins/video.php?href=${value}&show_text=0&width=560`
  }
  if (platform === 'twitch') {
    let type = ''
    let videoId = ''
    if (value.includes('https://clips.twitch.tv/')) {
      type = 'clip'
      videoId = value.split('/')[value.split('/').length - 1]
    }
    if (value.includes('/clip/')) {
      type = 'clip'
      videoId = value.split('/')[value.split('/').length - 1].split('?')[0]
    }
    if (value.includes('/v/')) {
      type = 'video'
      videoId = value.split('/')[value.split('/').length - 1].split('?')[0]
    }
    if (value.includes('https://www.twitch.tv/videos/')) {
      type = 'video'
      videoId = value.split('/')[value.split('/').length - 1]
    }
    return type === 'video'
      ? `https://player.twitch.tv/?video=${videoId}&parent=https://www.goclink.me`
      : `https://clips.twitch.tv/embed?clip=${videoId}&parent=https://www.goclink.me`
  }
  if (platform === 'vimeo') {
    const splittedUrl = value.split('/')
    const videoId = splittedUrl[splittedUrl.length - 1]
    return `https://player.vimeo.com/video/${videoId}?h=7c2c2a8171&title=0&byline=0&portrait=0`
  }
}
