import { doc, onSnapshot } from 'firebase/firestore'
import { useCallback, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { db } from '@/Firebase/Database'
import { useGetWhitelabel } from '@/Hooks'

export const useListenerUser = ({ uid, setUser, validate }) => {
  let unsubscribeUser = useRef()
  const dispatch = useDispatch()
  const whitelabel = useGetWhitelabel()

  const handleSnapshot = useCallback(
    snap => {
      const data = snap.data()

      if (
        data?.uid &&
        (data?.whiteLabel === whitelabel?.slug || !whitelabel?.slug)
      ) {
        const isValid = validate(data)
        if (!isValid) {
          return
        }
        dispatch(setUser(data))
        return
      }

      dispatch(setUser(null))
    },

    [dispatch, setUser, validate, whitelabel?.slug],
  )

  useEffect(() => {
    if (uid) {
      const userRef = doc(db, 'users', uid)
      if (unsubscribeUser.current) {
        unsubscribeUser.current()
      }

      unsubscribeUser.current = onSnapshot(userRef, handleSnapshot, error => {
        console.log(error)
      })

      return () => unsubscribeUser.current()
    } else {
      dispatch(setUser(null))
    }
  }, [dispatch, handleSnapshot, uid, setUser])
}
