import * as React from 'react'
const SvgLineParagraph = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 25})`}>
      <path
        stroke="#58637D"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M4 19.738h12m4-7H4m16-7H4"
      />
    </g>
  </svg>
)
export default SvgLineParagraph
