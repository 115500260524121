const get_by_csv = {
  upload: {
    select_csv_file: 'Seleccionar archivo CSV',
    to_upload: 'para cargar',
    or_drag_and_drop: 'o arrástralo aquí',
    must_be: 'Debe ser',
    file_with_maximum: 'archivo con un máximo de',
    rows: 'filas',
    import_limited:
      'La importación está limitada a 100 filas por vez. Por favor, use un archivo más pequeño.',
    upload: 'Cargar',
    how_to_format_csv: 'Cómo formatear el CSV',
    look_possible_columns:
      'Mira las columnas posibles a continuación o descarga la plantilla de CSV.',
    name_fields_for_profile_information:
      'Nombre los campos a continuación para encontrar fácilmente la información sobre el perfil de sus colaboradores.',
    use_preformatted_csv_template:
      'Puedes usar la plantilla CSV preformateada proporcionada. Completa cada campo asociado con la información de tus colaboradores.',
    download_template: 'Descargar plantilla de CSV',
  },
  mapping_table: {
    select_field: 'Seleccionar campo',
    select_csv_column: 'Seleccionar columna CSV',
    sample_data: 'Datos de muestra',
    required: '(requerido)',
    unmaped: 'No asignado',
  },
  mapping: {
    invalid_value_for: 'Valor inválido para ',
    check_your_csv_file_at_column: '. Verifique su archivo CSV en la columna: ',
    row: ' fila:',
    mapping: 'Mapeo',
    choose_columns: 'Elija las columnas que desea importar',
    we_have_found:
      'Esta es una vista previa de su archivo CSV y hemos encontrado',
    to_use: 'para usar.',
    select_columns: 'Seleccione las columnas que desea incluir a continuación',
  },
}

export default get_by_csv
