import useGetWhitelabel from './useGetWhitelabel'

export default function useGetBaseUrl({ whitelabel = true } = {}) {
  const whitelabelData = useGetWhitelabel()

  const defaultBaseUrl = `${window.origin}${
    whitelabel ? '/' + whitelabelData?.slug : ''
  }`
  return `${whitelabelData?.customDomain || defaultBaseUrl}`.replace('www.', '')
}
