const signup = {
  title_page: 'Create An Account',
  create_your_account: 'Create your account',
  join_the_community: 'Join the {{whitelabelName}} community',
  inputs: {
    email: 'Your email',
    email_address_is_invalid: 'The email address is invalid',
    password: 'Your password',
    confirm: 'Confirm your password',
  },
  others: {
    continue: 'Continue',
    agree: 'By registering, you accept our',
    terms: 'conditions of use',
    and: ' and our',
    privacy: 'privacy policy',
    register: 'Register',
  },
  manually: 'Add manually',
  registration_not_allowed: 'You are not allowed to register.',
}

export default signup
