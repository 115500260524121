const edit_profil = {
  preview: 'Voir mon profil',
  save: 'Sauvegarder',
  insight: 'Aperçu',
  reset: 'Réinitialiser',
  title: 'Éditer mon profil',
  manage_contents: 'Gérer mes contenus',
  notif: {
    succes: 'Les informations ont bien été enregistrées',
    champ: "Aucun champ n'a été modifié",
    not_enough_coins: "Vous n'avez pas assez de pièces pour faire cet achat",
    error: "Une erreur est survenue. Réessayez plus tard s'il vous plaît",
    interior: 'Intérieur',
    subtitle_interior:
      "L'intérieur correspond à la forme des globes oculaires de votre QR Code",
    exterior: 'Extérieur',
    subtitle_exterior:
      "L'extérieur correspond à la forme des montures des yeux de votre QR Code",
  },
  contents: {
    title: 'Contenus',
    subtitle: 'Organisez vos contenus et partagez-les depuis votre profil.',
    add_content_panel_titles: {
      contact: 'Ajouter un nouveau lien de contact',
      link: 'Ajouter un nouveau lien',
      social: 'Ajouter un nouveau lien social',
      gallery: 'Ajouter une nouvelle image à la galerie',
    },
    edit_content_panel_titles: {
      contact: 'Editer un lien de contact',
      link: 'Editer un lien',
      social: 'Editer un lien social',
      gallery: 'Editer une image à la galerie',
    },
  },
  qr_code: {
    title: 'QR Code',
    subtitle:
      'Personnalisez votre QRCode pour le rendre unique et reconnaissable par votre réseau.',
    pay_and_save:
      'Pour voir les changements selectionnés, payez et sauvegardez votre panier',
  },
  informations: {
    title: 'Vos informations',
    subtitle: 'Toutes les informations sont visible par les utilisateurs.',
    appearance: 'Apparence',
    modify_photos: 'Modifier mes photos',
    add_photos: 'Ajouter des photos',
    add_pp: 'Ajouter une photo de profil',
    add_bg: 'Ajouter une photo de couverture',
    add_logo: "Ajouter un logo d'entreprise",
    my_infos: {
      title: 'Informations',
      name: 'Prénom',
      name_placeholder: 'Votre prénom',
      surname: 'Nom',
      surname_placeholder: 'Votre nom',
      job: 'Poste',
      job_placeholder: 'Votre poste',
      enterprise: 'Entreprise',
      enterprise_placeholder: 'Votre entreprise',
      adresse: 'Adresse',
      adresse_placeholder: 'Votre adresse',
      description: 'Description',
      description_placeholder: 'Votre description',
    },
    hashtags: {
      title: 'Vos hashtags',
      add_hashtags: 'Ajouter un hashtag',
      error1: '3 hashtags maximum',
      error2: 'Entre 3 et 12 caractères',
      error3: 'Répétition interdite du hashtag',
    },

    advanced_options: {
      private_mode: 'Mode privé',
      title: 'Options avancées',
      business_profile: 'Profil entreprise',
    },

    save: 'Sauvegarder',
    insight: 'Aperçu',
  },
  appearance: {
    title: 'Apparence',
    subtitle: 'Démarquez vous en personnalisant votre profil.',
    color_theme: {
      title: 'Couleur du thème',
      color: 'Couleurs',
      perso: 'perso',
      grey: 'gris',
      blue: 'bleu',
      red: 'rouge',
      yellow: 'jaune',
      green: 'vert',
      violet: 'violet',
      orange: 'orange',
      color_perso: 'Couleur personnalisée',
      restart: 'Réinitialiser',
      preview_btn: 'Aperçu',
      default: 'défaut',
    },
    shape_avatar: {
      title: "Forme de l'avatar",
      shapes: 'Formes',
      motif: 'Motif',
      motif_subtitle:
        'Formes et couleurs des montures des yeux de votre QR Code',
      bg: 'Arrière-plan',
      eye: 'Yeux',
    },
    btn_style: {
      title: 'Style des boutons',
      shape: 'Forme',
      styles: 'Style',
      show_background: 'Afficher le fond',
      show_outline: 'Afficher le contour',
      show_content_as_list: 'Afficher les contenus sous forme de liste',
      outline_color: 'Couleur du contour',
    },
    hide_informations: {
      title: 'Informations',
      view_profil: 'Masquer les vues sur votre profil',
      hide_btn_contact: 'Masquer le bouton "Ajouter la fiche contact"',
      hide_contact: 'Masquer vos contacts',
      hide_followers: 'Masquer vos abonnés',
      hide_following: 'Masquer vos abonnements',
      hide_contents_subtitle: 'Masquer le sous-titre des contenus',
      hide_sharing_btn: 'Masquer les boutons de partage sur mon contenu',
      show_certification: 'Afficher la certification',
      certification: 'Certification',
    },
    replica_profil: {
      title: 'Répliquer un profil',
      replica: 'Répliquer',
      url_profile: "URL du profil ou nom d'utilisateur",
      dont_forget_to_save: 'Un profil a été répliqué, pensez à sauvegarder',
    },
    text_style: {
      title: 'Style de texte',
    },
    social_color: {
      title: 'Couleur des réseaux',
    },
    logo: {
      preview_text: 'Modifier le logo',
      label_slider: 'Taille',
    },
    buy_items_modal: {
      cart: 'Panier',
      title: 'Vous êtes sur le point de dépenser {{totalPrice}} pièces.',
      pay_and_save_btn: 'Payer et sauvegarder',
      item_descriptions: {
        'background-color': 'Couleur de thème personnalisée',
        'shape-avatar': "Forme d'avatar personnalisée",
        btnShape: 'Forme de bouton personnalisée',
        btnOutlineColor: 'Couleur de contour personnalisée',
        btnAddIsHide: "Cacher le bouton 'Ajouter'",
        duplicateProfile: "Duplication d'un profil",
        hideContentsSubtitle: 'Cacher le sous-titre du contenu',
        hideFollowers: 'Cacher les abonnés',
        hideFollowing: 'Cacher les personnes suivies',
        hideSharingBtnContent: 'Cacher les boutons de partage',
        banner: 'Bannière',
        isCertified: 'Certification',
        'card-color': 'Couleur de la carte',
        'qr-background-color': 'Couleur de fond du QRCode',
        'qr-pattern-shape': 'Forme du pattern du QRCode',
        'qr-eye-ball-shape': 'Forme des yeux du QRCode',
        'qr-eye-frame-shape': 'Forme des montures du QRCode',
        'card-template': 'Template de carte',
        'qr-background-color-custom':
          "Couleur personnalisée de l'arrière-plan du QR code",
        'qr-background-color-custom-eyes-interior':
          "Couleur personnalisée de l'intérieur des yeux du QR Code",
        'qr-background-color-custom-eyes-outside':
          "Couleur personnalisée de l'extérieur des yeux du QR Code",
        'qr-background-color-shape-custom':
          'Couleur personnalisée du motif du QR Code',
      },
    },
    acquired: 'Acquis',
    used: 'Utilisé',
    select_color: 'Sélectionnez une couleur',
  },
}

export default edit_profil
