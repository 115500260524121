import React, { createContext, useContext } from 'react'
import { useWindowSize } from './Hooks'
import DownloadApp from './Containers/DownloadApp/DownloadApp'
import { useParams } from 'react-router-dom'

const ResizeContext = createContext()

export const ResizeProvider = ({ children }) => {
  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const params = useParams()
  if (params.slug) {
    return children
  }
  const isSmallWidth = windowWidth < 940
  const isSmallHeight = windowHeight < 580

  return (
    <ResizeContext.Provider value={{ isSmallWidth }}>
      {isSmallWidth || isSmallHeight ? <DownloadApp /> : children}
    </ResizeContext.Provider>
  )
}

export function useResizeContext() {
  return useContext(ResizeContext)
}
