import LabeledSwitch from '@/Components/Controls/LabeledSwitch'
import { useCosmetics } from '@/Components/Dashboard/Contexts/CosmeticsContext'
import { useEditCurrentUser } from '@/Components/Dashboard/Contexts/EditProfileContext'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

const HideOptionBloc = () => {
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser()
  const {
    // isDisplayViews,
    // hideFollowing,
    // hideFollowers,
    // btnAddIsHide,
    hideContentsSubtitle,
    hideSharingBtnContent,
    profileDisplay,
    phone,
    phoneVerified,
    isCertified,
  } = editCurrentUser
  const { t } = useTranslation()

  const cosmetics = useCosmetics()

  const options = useMemo(() => {
    return [
      // {
      //   text: t('edit_profil.appearance.hide_informations.view_profil'),
      //   name: 'isDisplayViews',
      //   value: !isDisplayViews,
      // },
      // {
      //   text: t('edit_profil.appearance.hide_informations.hide_following'),
      //   name: 'hideFollowing',
      //   value: hideFollowing,
      // },
      // {
      //   text: t('edit_profil.appearance.hide_informations.hide_followers'),
      //   name: 'hideFollowers',
      //   value: hideFollowers,
      // },
      // {
      //   text: t('edit_profil.appearance.hide_informations.hide_btn_contact'),
      //   name: 'btnAddIsHide',
      //   value: btnAddIsHide,
      // },
      ...(profileDisplay !== 'icon'
        ? [
            {
              text: t(
                'edit_profil.appearance.hide_informations.hide_contents_subtitle',
              ),
              name: 'hideContentsSubtitle',
              value: hideContentsSubtitle,
            },
            {
              text: t(
                'edit_profil.appearance.hide_informations.hide_sharing_btn',
              ),
              name: 'hideSharingBtnContent',
              value: hideSharingBtnContent,
            },
          ]
        : []),
      ...(phone && phoneVerified
        ? [
            {
              text: t(
                'edit_profil.appearance.hide_informations.show_certification',
              ),
              name: 'isCertified',
              value: isCertified,
            },
          ]
        : []),
    ]
  }, [
    // isDisplayViews,
    // hideFollowing,
    // hideFollowers,
    // btnAddIsHide,
    profileDisplay,
    hideContentsSubtitle,
    hideSharingBtnContent,
    phone,
    phoneVerified,
    isCertified,
    t,
  ])

  const onChange = (name, value) => {
    const cosmetic = cosmetics[name] ? cosmetics[name][0] : null

    if (!cosmetic) {
      return setEditCurrentUser(user => ({
        ...user,
        [name]: !user[name],
      }))
    }

    const isDefaultValue = !value
    const isAcquired = cosmetic ? cosmetic.isAcquired : true
    const cantBeBuy = name === 'isDisplayViews'
    if (cantBeBuy || isAcquired || isDefaultValue) {
      return setEditCurrentUser(user => ({
        ...user,
        [name]: value,
      }))
    }
    setEditCurrentUser(user => ({
      ...user,
      [name]: value,
    }))
  }

  return (
    <>
      {options.map(({ text, name, value }) => (
        <div key={name} className="mb-3 w-full">
          <LabeledSwitch
            title={text}
            value={value}
            onChange={val => onChange(name, val)}
            variant="secondary"
          />
        </div>
      ))}
    </>
  )
}

export default HideOptionBloc
