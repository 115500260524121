import { memo, useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import { useEditCurrentUser } from '../../Contexts/EditProfileContext'
import { useCosmetics } from '../../Contexts/CosmeticsContext'
import { useTranslation } from 'react-i18next'
import { isValidUrl } from '@/Utils/validators'
import { Button } from '@/Components/Buttons/Button'
import { useLazyGetUserByCustomFieldForWhitelabelQuery } from '@/Services/modules/users'
import TextInput from '@/Components/Controls/TextInput'
import Text from '@/Components/Texts/Text'

const Replicate = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.colors.grey2};
  `}
`

const ReplicateProfile = memo(() => {
  const [url, setUrl] = useState('')
  const [getUserByUrl] = useLazyGetUserByCustomFieldForWhitelabelQuery()
  const [getUserByCustomFieldForWhitelabel] =
    useLazyGetUserByCustomFieldForWhitelabelQuery()
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser()
  const [haveProfile, setHaveProfile] = useState(false)
  const { duplicateProfile } = useCosmetics()
  const { t } = useTranslation()

  const onDuplicate = useCallback(async () => {
    try {
      let response
      const fieldIsUrl = isValidUrl(url)
      if (fieldIsUrl) {
        response = await getUserByUrl({
          whitelabel: editCurrentUser.whiteLabel,
          url,
        })
      } else {
        response = await getUserByCustomFieldForWhitelabel({
          customUrl: url.toLowerCase(),
          whitelabel: editCurrentUser.whiteLabel,
        })
      }

      if (response.error) {
        throw new Error(response.error.data.error)
      }

      setHaveProfile(true)
      const profile = response.data
      setEditCurrentUser({
        ...editCurrentUser,
        styles: profile.styles,
        isDisplayViews: profile.isDisplayViews,
        btnAddIsHide: profile.btnAddIsHide,
        hideFollowing: profile.hideFollowing,
        hideFollowers: profile.hideFollowers,
      })
    } catch (error) {
      // TODO TOAST
      // Toast.show({
      //   type: 'error',
      //   text1: error.message || t('toastErrorDefault.error'),
      //   props: {
      //     closable: true,
      //   },
      // })
    }
  }, [
    url,
    editCurrentUser,
    getUserByCustomFieldForWhitelabel,
    getUserByUrl,
    setEditCurrentUser,
  ])

  return (
    <>
      <TextInput
        // adornment={<LineLink color={theme.colors.black} />}
        placeholder={t('edit_profil.appearance.replica_profil.url_profile')}
        value={url}
        onChange={setUrl}
        variant="secondary"
      />
      <Button
        className="h-12 mt-3 ml-auto"
        label={t('edit_profil.appearance.replica_profil.replica')}
        disabled={!url}
        onClick={onDuplicate}
      />
      {haveProfile && (
        <Replicate className="mt-3 text-center">
          {t('edit_profil.appearance.replica_profil.dont_forget_to_save')}
        </Replicate>
      )}
    </>
  )
})

export default ReplicateProfile
