import React from 'react'
import bannerDownloadApp from '../../Assets/images/downloadApp/content-dl-app.webp'
import styled from 'styled-components'

const BgImage = styled.div`
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  background-color: ${({ theme }) => theme.colors.primary};
`
export const BannerContent = () => {
  return (
    <BgImage
      className="h-full rounded-3xl flex flex-col justify-end"
      imageUrl={bannerDownloadApp}
    />
  )
}
