const get_by_csv = {
  upload: {
    select_csv_file: 'Sélectionner un fichier CSV',
    to_upload: 'à charger',
    or_drag_and_drop: 'ou faites un glisser-déposer',
    must_be: 'Doit être',
    file_with_maximum: 'fichier avec un maximum de',
    rows: 'lignes',
    import_limited:
      "L'importation est limitée à 100 lignes à la fois. Veuillez utiliser un fichier plus petit.",
    upload: 'Charger',
    how_to_format_csv: 'Comment formater le CSV',
    look_possible_columns:
      'Consultez les colonnes possibles ci-dessous ou téléchargez le modèle CSV.',
    name_fields_for_profile_information:
      'Nommez les champs ci-dessous pour retrouver facilement les informations sur le profil de vos collaborateurs.',
    use_preformatted_csv_template:
      'Vous pouvez utiliser le modèle CSV pré-formaté. Remplissez chaque champ associé avec les informations de vos collaborateurs.',
    download_template: 'Télécharger le modèle CSV',
  },
  mapping_table: {
    select_field: 'Sélectionner un champ',
    select_csv_column: 'Sélectionner une colonne CSV',
    sample_data: "Données d'exemple",
    required: '(obligatoire)',
    unmaped: 'Non mappé',
  },
  mapping: {
    invalid_value_for: 'Valeur invalide pour ',
    check_your_csv_file_at_column:
      '. Vérifiez votre fichier CSV à la colonne : ',
    row: ' ligne :',
    mapping: 'Mapping',
    choose_columns: 'Choisissez les colonnes à importer',
    we_have_found:
      "Il s'agit d'un aperçu de votre fichier CSV et nous avons trouvé",
    to_use: 'à utiliser.',
    select_columns: 'Sélectionnez les colonnes à inclure ci-dessous',
  },
}

export default get_by_csv
