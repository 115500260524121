import * as React from 'react'
const SvgFilledHeart = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 25})`}>
      <path
        fill="#58637D"
        stroke="#58637D"
        d="m11.31 21.787-.008-.002c-1.83-.628-4.231-2.07-6.17-4.194-1.935-2.119-3.382-4.884-3.382-8.163 0-3.226 2.608-5.84 5.81-5.84 1.558 0 3.01.607 4.085 1.692l.355.358.355-.358a5.71 5.71 0 0 1 4.085-1.692c3.201 0 5.81 2.623 5.81 5.84 0 3.284-1.447 6.05-3.382 8.166-1.938 2.123-4.34 3.563-6.17 4.19l-.007.003c-.18.065-.419.1-.691.1s-.511-.035-.69-.1Zm.158-.466.004.001c.166.056.372.07.533.07s.367-.014.533-.07l.004-.001c1.217-.418 3.502-1.601 5.491-3.558 1.995-1.963 3.727-4.741 3.727-8.335 0-2.944-2.381-5.34-5.31-5.34a5.25 5.25 0 0 0-4.242 2.143c-.03.04-.097.082-.198.082s-.168-.042-.197-.082l-.003-.003a5.27 5.27 0 0 0-4.25-2.14c-2.929 0-5.31 2.396-5.31 5.34 0 3.589 1.73 6.367 3.723 8.331 1.987 1.958 4.273 3.144 5.495 3.562ZM3.25 9.428c0-2.397 1.939-4.34 4.31-4.34a4.27 4.27 0 0 1 3.449 1.738c.25.34.643.487 1.001.487.359 0 .752-.148 1.002-.488a4.25 4.25 0 0 1 3.438-1.737c2.371 0 4.31 1.943 4.31 4.34 0 3.246-1.558 5.783-3.428 7.622-1.874 1.844-4.026 2.95-5.11 3.324a1.332 1.332 0 0 1-.433 0c-1.09-.374-3.241-1.482-5.114-3.327-1.87-1.841-3.425-4.378-3.425-7.62Z"
      />
    </g>
  </svg>
)
export default SvgFilledHeart
