import React, { useState } from 'react'
import Slider from 'react-slick'
import styled, { css } from 'styled-components'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const CarouselWrapper = styled.div`
  ${({ theme }) => css`
    border-radius: ${theme.borderRadius.l};
    overflow: hidden;
    margin-bottom: ${theme.space.std};
    background-color: ${theme.colors.grey1};
    position: relative;
  `}
`
const CarousselPagination = styled.span`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.space.xs};
    right: ${theme.space.xs};
    background-color: ${theme.colors.black};
    color: ${theme.colors.white};
    z-index: 1;
    border-radius: ${theme.borderRadius.m};
    padding: ${theme.space.xxs} ${theme.space.xs};
  `}
`

export const CarouselPost = ({ images = [] }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)

  if (!images.length) {
    return null
  }
  return (
    <CarouselWrapper>
      {images.length > 1 && (
        <CarousselPagination>{`${selectedImageIndex + 1} / ${
          images.length
        }`}</CarousselPagination>
      )}
      <Slider
        arrows={false}
        afterChange={index => setSelectedImageIndex(index)}
        infinite={false}
      >
        {images.map((image, i) => (
          <img key={i} src={image} alt={'image_post' + i} />
        ))}
      </Slider>
    </CarouselWrapper>
  )
}
