import React, { useEffect } from 'react'
import { Router } from './Router/Router'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/es'
import 'moment/locale/ar'
import { useTranslation } from 'react-i18next'
import './global.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-day-picker/dist/style.css'
import { Provider, useSelector } from 'react-redux'
import { persistor, store } from '@/Store'
import { searchClient } from './algolia'
import { InstantSearch } from 'react-instantsearch'
import { IntercomProvider } from 'react-use-intercom'
import { WhitelabelProvider } from './Services/whitelabel/WhitelabelProvider'
import { ThemeProvider } from './Theme/ThemeProvider'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { HelmetProvider } from 'react-helmet-async'

const Application = () => {
  const language = useSelector(state => state.language.lang)
  const { i18n } = useTranslation()

  useEffect(() => {
    i18n.changeLanguage(language)
    moment.locale(language)
  }, [language, i18n])

  return (
    <WhitelabelProvider>
      {whitelabel => (
        <IntercomProvider appId={whitelabel?.livechatToken} autoBoot>
          <ThemeProvider whitelabel={whitelabel}>
            <Router />
            <ToastContainer theme="light" position="top-center" limit={3} />
          </ThemeProvider>
        </IntercomProvider>
      )}
    </WhitelabelProvider>
  )
}

const App = () => {
  return (
    <HelmetProvider>
      <InstantSearch searchClient={searchClient} indexName="users">
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Application />
          </PersistGate>
        </Provider>
      </InstantSearch>
    </HelmetProvider>
  )
}

export default App
