import { Config } from '@/Config'
import { auth } from '@/Firebase/Auth'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getIdToken } from 'firebase/auth'

const baseQuery = fetchBaseQuery({
  baseUrl: Config.API_URL,
  prepareHeaders: async headers => {
    try {
      const user = auth.currentUser
      const token = await getIdToken(user)
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    } catch (err) {
      return headers
    }
  },
})

const baseQueryWithInterceptor = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)
  return result
}

export const api = createApi({
  baseQuery: baseQueryWithInterceptor,
  tagTypes: [
    'Posts',
    'UserPosts',
    'Followers',
    'Followings',
    'User',
    'Bookmarks',
    'ImagesByHashtag',
    'PostsByHashtag',
    'UsersByHashtag',
    'Whitelabel',
    'BlockedUsers',
    'BusinessCards',
    'Notifications',
    'Companion',
    'CompanionActivity',
    'Cosmetics',
    'UserConfig',
    'Yields',
    'YieldsHistory',
    'Trees',
    'IndividualTrees',
    'CollectiveTrees',
    'MyOffers',
    'GameRanking',
    'GameHistory',
    'BestScore',
    'ViewedAds',
    'AvailableResources',
    'ResourcesGained',
    'Streaks',
    'DailyRewards',
    'DetailsTrees',
    'CurrentTreeSeeds',
    'DailyRewards',
    'Progress',
    'Quests',
    'ReminderMall',
    'TeamMembers',
    'UserProducts',
    'UsersByWhitelabel',
  ],
  endpoints: () => ({}),
})
