import * as React from 'react'
const SvgLineHappy = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 24}
    height={props.size || 24}
    fill="none"
    {...props}
  >
    <g transform={`scale(${(props.size || 24) / 40})`}>
      <path
        fill="#EBF8EB"
        fillRule="evenodd"
        d="M19.877 0h.246c4.186 0 7.735.835 10.669 2.404a16.36 16.36 0 0 1 6.804 6.804C39.165 12.142 40 15.691 40 19.878v.245c0 4.186-.836 7.735-2.404 10.669a16.36 16.36 0 0 1-6.804 6.804C27.858 39.165 24.309 40 20.122 40h-.245c-4.186 0-7.735-.836-10.669-2.404a16.36 16.36 0 0 1-6.804-6.804C.835 27.858 0 24.309 0 20.122v-.245c0-4.186.835-7.735 2.404-10.669a16.36 16.36 0 0 1 6.804-6.804C12.142.835 15.691 0 19.878 0"
        clipRule="evenodd"
      />
      <path
        stroke="#58637D"
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M17 23.738c.85.63 1.885 1 3 1s2.15-.37 3-1"
      />
      <path
        fill="#58637D"
        stroke="#58637D"
        d="M23.5 18.238c0 .33-.09.605-.209.783-.121.182-.234.217-.291.217s-.17-.035-.291-.217a1.44 1.44 0 0 1-.209-.783c0-.33.09-.605.209-.783.121-.182.234-.217.291-.217s.17.035.291.217c.12.178.209.454.209.783Z"
      />
      <path
        fill="#58637D"
        d="M17 19.738c.552 0 1-.672 1-1.5s-.448-1.5-1-1.5-1 .672-1 1.5.448 1.5 1 1.5"
      />
      <path
        stroke="#58637D"
        strokeWidth={1.5}
        d="M11.353 16.688a7.51 7.51 0 0 1 5.597-5.597c2.006-.47 4.094-.47 6.1 0a7.51 7.51 0 0 1 5.597 5.597c.47 2.006.47 4.094 0 6.1a7.51 7.51 0 0 1-5.597 5.597c-2.006.47-4.094.47-6.1 0a7.51 7.51 0 0 1-5.597-5.597 13.35 13.35 0 0 1 0-6.1Z"
      />
    </g>
  </svg>
)
export default SvgLineHappy
