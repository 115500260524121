import React from 'react'
import { useOnboardingContext } from '../OnBoardingContext'
import { useTheme } from '@/Hooks'
import LabeledInput from '@/Components/Controls/LabeledInput'
import { LineHappy2, LineUser, LineUserPlus } from '@/Assets/icons/components'
import Text from '@/Components/Texts/Text'
import { useTranslation } from 'react-i18next'
import capitalizeFirstLetter from '@/Utils/capitalizeFirstLetter'
import ModalSponsorCode from '@/Components/Modals/ModalSponsorCode'
import { allowedCharactersRegex, isValidCustomUrl } from '@/Utils/validators'
import { toast } from 'react-toastify'

const title = 'onboarding.step2.create_your_profile'
const subtitle = 'onboarding.step2.enter_your_first_name'

const Profile = () => {
  const { theme } = useTheme()
  const { values, setValues } = useOnboardingContext()
  const { t } = useTranslation()
  const fields = [
    {
      title: 'onboarding.username_title',
      name: 'customUrl',
      placeholder: 'onboarding.your_username',
      adornment: () => <LineHappy2 color={theme.colors.grey2} />,
      format: value => sanitizeUsername(value).toLowerCase(),
      maxLength: 20,
      require: true,
    },
    // {
    //   title: 'onboarding.referral_code',
    //   name: 'sponsorId',
    //   placeholder: 'onboarding.your_godfather',
    //   adornment: () => <LineUserPlus color={theme.colors.grey2} />,
    //   optional: true,
    //   renderChildren: () => {
    //     return (
    //       <ModalSponsorCode handleAddSponsorId={setValues}>
    //         {openModal => (
    //           <div
    //             className="absolute right-4 cursor-pointer"
    //             onClick={() => openModal(true)}
    //           >
    //             <Text
    //               className="font-light text-xs"
    //               regular
    //               color={theme.colors.primary}
    //             >
    //               {capitalizeFirstLetter(t('feedpage.search'))}
    //             </Text>
    //           </div>
    //         )}
    //       </ModalSponsorCode>
    //     )
    //   },
    // },
    ...(!values.businessMode
      ? [
          {
            title: 'edit_profil.informations.my_infos.job',
            name: 'job',
            placeholder: 'edit_profil.informations.my_infos.job_placeholder',
            adornment: () => <LineUser color={theme.colors.grey2} />,
            optional: true,
          },
        ]
      : []),
    {
      title: 'edit_profil.informations.my_infos.enterprise',
      name: 'businessName',
      placeholder: 'edit_profil.informations.my_infos.enterprise_placeholder',
      adornment: () => <LineUser color={theme.colors.grey2} />,
      optional: !values.businessMode,
    },
  ]
  const sanitizeUsername = value => {
    return value.replace(allowedCharactersRegex, '')
  }
  return (
    <>
      {fields.map(
        ({
          name,
          title,
          placeholder,
          renderChildren = () => null,
          format = v => v,
          ...field
        }) => {
          return (
            <LabeledInput
              color={theme.colors.grey3}
              className="font-light text-sm"
              value={values[name] || ''}
              onChange={value => {
                setValues({
                  ...values,
                  [name]: format(value),
                })
              }}
              title={t(title)}
              placeholder={t(placeholder)}
              {...field}
            >
              {renderChildren()}
            </LabeledInput>
          )
        },
      )}
    </>
  )
}

const ProfileSection = {
  title,
  subtitle,
  component: Profile,
  step: 1,
  getDisabled: values => {
    const isCustomUrlEmpty = values.customUrl?.length === 0
    const isEitherFieldEmpty =
      !values.customUrl?.trim() || !values.businessName?.trim()
    if (values.businessMode) {
      return isEitherFieldEmpty
    }
    return isCustomUrlEmpty
  },
  checkErrors: values => {
    const valid = isValidCustomUrl(values.customUrl)
    if (!valid) {
      return ['toastOnBoarding.error']
    }

    return []
  },
  onSubmit: async ({ values, setCurrentUser, setLoading, t }) => {
    setLoading(true)
    const adminId = 'FEC2A60F'

    try {
      const data = {
        businessMode: values.businessMode,
        customUrl: values.customUrl,
        sponsorId: values.sponsorId.trim() || adminId,
        job: values.job.trim(),
        businessName: values.businessName.trim(),
      }
      await setCurrentUser(data)
    } catch (err) {
      const userNameError = err?.message === 'Error: Username already used'
      toast.error(
        userNameError
          ? t('others.errors.username_already_used')
          : t('toastErrorDefault.error'),
      )
      return { error: err }
    } finally {
      setLoading(false)
    }
  },
  section: 1,
}

export default ProfileSection
