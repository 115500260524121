import themeGlobal, { darkColors, lightColors } from '@/Theme'
import { useMemo, useEffect } from 'react'
import useGetWhitelabel from './useGetWhitelabel'
import { useSelector } from 'react-redux'

const useTheme = initWhitelabel => {
  const whitelabel = useGetWhitelabel()
  const { isDarkMode } = useSelector(state => state.theme)
  const colors = useMemo(
    () => (!isDarkMode ? lightColors : darkColors),
    [isDarkMode],
  )

  const theme = useMemo(
    () => ({
      ...themeGlobal,
      colors: {
        ...colors,
        primary: initWhitelabel?.color2 || whitelabel?.color2 || colors.primary,
      },
    }),
    [colors, initWhitelabel?.color2, whitelabel?.color2],
  )

  useEffect(() => {
    if (isDarkMode) {
      document.body.style.background = darkColors.white
      return
    }
    document.body.style.background = lightColors.white
  }, [isDarkMode])

  return { theme, isDarkMode }
}

export default useTheme
