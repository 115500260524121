const onboarding = {
  page_title: 'Introducción',
  optional: 'Opcional',
  about_title: 'Acerca de',
  interests: 'Intereses',
  selected: 'Seleccionado',
  calendar: 'Calendario',
  username_title: 'Nombre de usuario',
  your_username: 'Tu nombre de usuario',
  referral_code: 'Código de referencia',
  your_godfather: 'Tu padrino',
  about: {
    male: 'Masculino',
    female: 'Feminino',
    society: 'Sociedad',
  },
  tags: {
    Business: 'Negocios',
    Creatives: 'Creativos',
    Education: 'Educación',
    Entertainment: 'Entretenimiento',
    Fashion: 'Moda',
    Government_Politics: 'Gobierno y Política',
    Food_Beverages: 'Comida y Bebidas',
    Health_Wellness: 'Salud y Bienestar',
    Tech: 'Tecnología',
    Travel_Tourism: 'Viajes y Turismo',
    Other: 'Otro',
  },
  title: 'Cuéntanos sobre ti',
  subtitle: 'Para una experiencia personalizada',
  who_are_you: '¿Quién eres?',
  username: 'Su nombre de usuario',
  username_information: 'Solo se permiten letras y números *',
  sponsorUID: 'Código de patrocinador.',
  firstname: 'Tu nombre',
  lastname: 'Tu apellido',
  lastname_title: 'Apellido',
  birth_date: 'Tu fecha de nacimiento',
  birth_date_title: 'Fecha de nacimiento',
  business_name: 'Nombre de tu negocio',
  select_categories: 'Selecciona tres categorías que describan mejor tu perfil',
  continue: 'Continuar',
  verification_code: {
    verification: 'Verificación',
    title: 'Verificación de su correo electrónico',
    subtitle: '¡Haga clic en el enlace o introduzca el código que le enviamos!',
    back_to_verification: 'Ir a verificación de cuenta',
    info: 'Hemos enviado el código de verificación a ',
    resend: 'Reenviar el correo electrónico',
    verify_your_account: 'Verifique su cuenta',
    enter_your_verification: 'Ingresa tu código de verificación',
  },
  personalize_your: 'Personalice su experiencia',
  creating_your_profile: 'Creación de su perfil',
  enter_your_information: 'Ingrese su información',
  step1: {
    welcome: 'Te gustaría crear un perfil personal o profesional?',
    profile: 'Perfil',
    choose_individual:
      "Elige 'personal' para crear un perfil que te represente o 'profesional' para crear un perfil que represente a tu empresa",
    individual: {
      name: 'Nombre de pila',
      title: 'Personal',
      subtitle:
        'Utiliza un perfil para compartir tus intereses y actividades con la comunidad.',
    },
    business: {
      name: ' Nombre de la empresa',
      title: 'Negocio',
      subtitle:
        'Crea un perfil para tu empresa y comparte tus productos y servicios.',
    },
  },
  step2: {
    complete_your_budly: 'Completa tu perfil',
    introduce_yourself: 'Preséntate a los miembros de la comunidad.',
    your_profile_is: '¡Tu perfil está creado!',
    select_a_photo_and_a_banner:
      '¡Selecciona una foto y un banner para personalizar tu perfil!',
    create_your_profile: 'Crea tu perfil',
    enter_your_first_name:
      'Ingresa tu nombre para personalizar tu perfil. Si tienes un código de referencia, ingrésalo aquí para disfrutar de beneficios exclusivos!',
    help_others: 'Ayuda a otros miembros de la red a conocerte mejor.',
    describe_yourself: 'Describirse a la comunidad.',
  },
}

export default onboarding
