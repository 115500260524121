import { useTheme } from '@/Hooks'
import {
  ThemeProvider as MuiThemeProvider,
  useTheme as useMuiTheme,
  createTheme,
} from '@mui/material'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'

export const ThemeProvider = ({ whitelabel, children }) => {
  const { theme } = useTheme(whitelabel)
  const muiTheme = useMuiTheme()
  const customMuiTheme = createTheme({
    ...muiTheme,
    palette: {
      ...muiTheme.palette,
      primary: {
        main: whitelabel?.color2 || theme.colors.primary,
      },
    },
  })

  return (
    <div
      className={
        whitelabel
          ? 'opacity-100 transition'
          : 'opacity-0 pointer-events-none transition'
      }
    >
      <MuiThemeProvider theme={customMuiTheme}>
        <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
      </MuiThemeProvider>
    </div>
  )
}
