import React, { useState, memo } from 'react'
import styled, { css } from 'styled-components'
import { useProfileContext } from './Contexts/ProfileContext'
import { useTranslation } from 'react-i18next'
import { shapes } from '@/Utils/shapes'
import { LineExport } from '@/Assets/icons/components'
import { Button } from '../Buttons/Button'
import { useGetWhitelabel } from '@/Hooks'
import ModalAddToContact from '../Modals/ModaleAddToContact'

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    width: ${theme.size.fill};
    padding: ${theme.space.none} ${theme.space.m};
  `}
`

const ButtonWrapper = styled.div`
  ${({ theme }) => css`
    flex-grow: 1;
    display: flex;
    padding-right: ${theme.space.std};
    height: ${theme.size.m};
  `}
`

const ShareButton = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    width: ${theme.size.l};
    flex: 1;
    background: ${theme.colors.secondary};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${theme.borderRadius.m};
    border-color: ${props =>
      props.styles?.showBtnOutline
        ? props.styles?.btnOutlineColor || '#000000'
        : theme.colors.grey1};
    border-width: 1px;
    background-color: ${props =>
      props.styles?.hideBtnBackground ? 'transparent' : theme.colors.grey1};
    border-style: solid;
  `}
`

const ProfileAddContact = memo(() => {
  const { isFollow, profile } = useProfileContext()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const whitelabel = useGetWhitelabel()

  const buttonOptions = {
    icon: null,
    label: isFollow
      ? t('previewpage.follow.followed')
      : t('previewpage.follow.no_follow'),
  }

  const shape = shapes.find(
    btnShape => btnShape.shape === (profile.styles?.btnShape || 'shape1'),
  )

  const onDownloadVcard = () => {
    setLoading(true)
    window.location.href = `https://europe-west3-gopopme-7353a.cloudfunctions.net/createDownloadvCardTEST?uid=${profile.uid}&slug=${whitelabel?.slug}&customDomain=${whitelabel?.customDomain || ''}`
    setTimeout(() => setLoading(false), 700)
  }

  return (
    <>
      <Wrapper>
        <ButtonWrapper>
          <Button
            {...buttonOptions}
            loading={loading}
            onClick={onDownloadVcard}
            color={profile?.styles?.['background-color']}
            className={shape.className}
          />
        </ButtonWrapper>
        <ModalAddToContact>
          {setIsOpen => (
            <ShareButton
              onClick={() => setIsOpen(true)}
              className={shape.className}
              styles={profile.styles}
            >
              <LineExport />
            </ShareButton>
          )}
        </ModalAddToContact>
      </Wrapper>
    </>
  )
})

export default ProfileAddContact
