const shop = {
  screen_name: 'Tienda',
  free_ressources: 'Ofertas gratuitas',
  seeds_packages: 'Paquetes de semillas',
  coins_packages: 'Paquetes de monedas',
  drops_packages: 'Paquetes de gotas',
  tickets_packages: 'Paquetes de boletos',
  pack_title: 'Recibe {{quantity}} ',
  seed: 'semilla',
  coin: 'moneda',
  drop: 'gota',
  ticket: 'ticket',
  free_subtitle: 'Mira un anuncio para ganar una {{resource}} gratuita',
  free_seed_button: 'Mira un anuncio',
  already_recovered: 'Ya recuperado',
  remaining: 'restante{{plurial}}',
  five_seeds_pack_subtitle:
    'Each purchase increases the money donated to projects...',
  fifty_seeds_pack_subtitle: 'And help the Team develop ...',
  two_hundred_seeds_pack_subtitle: 'A lot of new features for you.',
  tab_all: 'Todo',
  tab_free: 'Gratuito',
  tab_coins: 'Monedas',
  thanks_for_purchase_title: '¡Gracias por su compra!',
  thanks_for_purchase_description: 'Tu compra ha sido realizada, ¡aprovéchala!',
  thanks_for_watching_add_description: '¡Tu recompensa te ha sido otorgada!',
  // available_soon: "Bientôt disponible",
  available_soon: 'Pronto disponible',
  shop_creation_success: 'Tu tienda ha sido creada con éxito',
}

export default shop
