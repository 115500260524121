import { useTranslation } from 'react-i18next'
import ProfileContacts from '../ProfileContacts'
import ProfileSocials from '../ProfileSocials'
import ProfileLinks from '../ProfileLinks'
import ProfileGallery from '../ProfileGallery'
import { useProfileContext } from '../Contexts/ProfileContext'
import { ProfileNews } from '../ProfileNews'
import {
  LineDiscovery,
  LineGallery,
  LineLink,
  LineMessage4,
  LineTwoUsers,
} from '@/Assets/icons/components'
import ProfileShop from '../ProfileShop'

export const useGetTabs = profile => {
  const { isAdmin, canSeeAllContacts } = useProfileContext()
  const { t } = useTranslation()
  if (!profile) {
    return []
  }

  const hasContacts =
    profile.contacts?.filter(
      contact =>
        contact.enabled && (canSeeAllContacts ? true : !contact.private),
    ).length > 0

  const hasSocials =
    profile.links?.filter(link => link.type === 'social' && link.enabled)
      .length > 0

  const hasGallery = profile.gallery?.length > 0

  const hasLinks =
    profile.links?.filter(link => link.type === 'link' && link.enabled).length >
    0

  const tabs = [
    ...(hasContacts
      ? [
          {
            title: t('profilepage.contact.title_tab'),
            icon: LineMessage4,
            component: ProfileContacts,
          },
        ]
      : []),
    ...(hasSocials
      ? [
          {
            title: t('profilepage.social.title_tab'),
            icon: LineTwoUsers,
            component: ProfileSocials,
          },
        ]
      : []),
    ...(hasLinks
      ? [
          {
            title: t('profilepage.link.title_tab'),
            icon: LineLink,
            component: ProfileLinks,
          },
        ]
      : []),
    ...(hasGallery
      ? [
          {
            title: t('profilepage.galerie.title_tab'),
            icon: LineGallery,
            component: ProfileGallery,
          },
        ]
      : []),
    // {
    //   title: t('previewpage.newstab.title_tab'),
    //   name: 'news',
    //   icon: LineDiscovery,
    //   component: ProfileNews,
    // },
    ...(profile?.shopRocket?.enable && profile?.shopRocket?.live_publishable_key
      ? [
          {
            title: 'Shop',
            name: 'shop',
            icon: LineDiscovery,
            component: ProfileShop,
          },
        ]
      : []),
    //  ...(!isAdmin
    //    ? [
    //        {
    //          title: t('activitypage.title_tab_bottom'),
    //          name: 'activity',
    //          icon: LineAward,
    //          component: ProfileActivity,
    //        },
    //      ]
    //    : []),
  ]

  return tabs
}
