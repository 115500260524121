export default build =>
  build.mutation({
    query: ({ slug, data }) => {
      return {
        url: `/whitelabel/${slug}`,
        method: 'PUT',
        body: data,
      }
    },
    invalidatesTags: ['Whitelabel'],
  })
