export default build =>
  build.query({
    query: uid => {
      return {
        url: `/users/${uid}/followers`,
        method: 'GET',
      }
    },
    providesTags: () => [
      { type: 'Followers', id: 'LIST' },
      { type: 'Followings', id: 'LIST' },
    ],
  })
