import { useTheme } from '@/Hooks'
import { useCosmetics } from '../../Contexts/CosmeticsContext'
import { useEditCurrentUser } from '../../Contexts/EditProfileContext'
import { useMemo } from 'react'
import { formatColors } from '../../../../Utils/formatColors'
import ColorSelection from '@/Components/ColorSelection'
import useCurrentUser from '@/Hooks/useCurrentUser'
import useCurrentMember from '@/Hooks/useCurrentMember'

const ThemeColor = () => {
  const { theme } = useTheme()
  const cosmetics = useCosmetics()
  const { currentUser } = useCurrentUser()
  const { currentMember } = useCurrentMember()
  const user = currentMember || currentUser
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser()

  const { colors, customColor } = useMemo(() => {
    return {
      colors: formatColors({
        colors: cosmetics?.['background-color'] || [],
        defaultColor: theme.colors.primary,
      }),
      customColor: cosmetics?.['background-color-custom']?.[0] || null,
    }
  }, [cosmetics, theme.colors.primary])

  const selectedColor = useMemo(
    () =>
      (
        editCurrentUser.styles['background-color'] || theme.colors.primary
      ).toLowerCase(),
    [theme.colors.primary, editCurrentUser],
  )

  const usedColor = useMemo(
    () =>
      (user.styles['background-color'] || theme.colors.primary).toLowerCase(),
    [theme.colors.primary, user],
  )

  const onAddToCart = item => {
    setEditCurrentUser(current => {
      return {
        ...current,
        styles: { ...current.styles, [item.type]: item.value },
      }
    })
  }

  return (
    <ColorSelection
      type="background-color"
      colors={colors}
      customColor={customColor}
      selectedColor={selectedColor}
      usedColor={usedColor}
      onAddToCart={onAddToCart}
    />
  )
}

export default ThemeColor
