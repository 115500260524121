import Appearance from '@/Containers/Dashboard/Profile/Appearance'
import BusinessCard from '@/Containers/Dashboard/Profile/BusinessCard'
import Contents from '@/Containers/Dashboard/Profile/Contents'
import Informations from '@/Containers/Dashboard/Profile/Informations'
import QrCode from '@/Containers/Dashboard/Profile/QrCode'
import ProfileContainer from '@/Containers/Profile/Profile'
import useCurrentUser from '@/Hooks/useCurrentUser'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { useState } from 'react'
import Phone from '../Phone'
import QrCodePreview from '../Profile/QrCode/QrCodePreview'
import { useUpdateQrCodeByIdMutation } from '@/Services/modules/qrcode'
import deepEqual from 'deep-equal'
import { QrCodeFooter } from '../Profile/QrCode/QrCodeFooter'
import BusinessCardPreview from '@/Components/BusinessCardPreview/BusinessCardPreview'
import { useEditBusinessCard } from './EditBusinessCardContext'
import useCurrentMember from '@/Hooks/useCurrentMember'
import { useSelectAllMembers } from '@/Hooks/useSelectAllMembers'
import { useSelector } from 'react-redux'

const EditProfileContext = createContext()

export const EditProfileProvider = ({ children }) => {
  const { currentUser, setCurrentUser } = useCurrentUser()
  const { currentMember, setCurrentMember, isMultiMemberEdition } =
    useCurrentMember()
  const [user, setUser] = [
    currentMember || currentUser,
    setCurrentMember || setCurrentUser,
  ]
  const { members } = useSelectAllMembers()
  const { checkedAllMemberUid } = useSelector(state => state.member)

  const [editCurrentUser, setEditCurrentUser] = useState(user)
  const [updateQrCodeById] = useUpdateQrCodeByIdMutation()

  const isQrCodeChanged = useMemo(() => {
    const isEqual = deepEqual(
      user.qrcode?.attributes,
      editCurrentUser.qrcode?.attributes,
    )
    return !isEqual
  }, [user.qrcode?.attributes, editCurrentUser.qrcode?.attributes])
  const { selectedBusinessCard } = useEditBusinessCard()

  const changes = useMemo(() => {
    return Object.entries(editCurrentUser).reduce(
      (acc, [key, value]) =>
        deepEqual(value, user[key]) === false
          ? {
              ...acc,
              [key]: typeof value === 'string' ? value.trim() : value,
            }
          : acc,
      {},
    )
  }, [editCurrentUser, user])

  const haveChanges = useMemo(() => Object.keys(changes).length > 0, [changes])
  const editQrcode = useCallback(async () => {
    if (!isMultiMemberEdition) {
      await updateQrCodeById({
        id: user.qrcode?.id,
        qrcodeData: {
          qr_type: 2,
          attributes: editCurrentUser.qrcode?.attributes,
        },
      })
      return
    }
    const memberSelected = members.filter(member =>
      checkedAllMemberUid.includes(member.uid),
    )
    await Promise.all(
      memberSelected.map(async member => {
        await updateQrCodeById({
          id: member.qrcode.id,
          qrcodeData: {
            qr_type: 2,
            attributes: editCurrentUser.qrcode?.attributes,
          },
        })
      }),
    )
  }, [
    checkedAllMemberUid,
    editCurrentUser.qrcode?.attributes,
    isMultiMemberEdition,
    members,
    updateQrCodeById,
    user.qrcode?.id,
  ])

  const onSubmit = useCallback(async () => {
    try {
      if (!haveChanges) {
        // TODO TOAST
        // Toast.show({
        //   type: 'warning',
        //   text1: t('edit_profil.notif.champ'),
        //   props: {
        //     closable: true,
        //   },
        // })
        return
      }

      if (isQrCodeChanged) {
        editQrcode()
      }
      await setUser(changes)
      // TODO TOAST
      //   Toast.show({
      //     type: 'success',
      //     text1: t('edit_profil.notif.succes'),
      //     props: {
      //       closable: true,
      //     },
      //   })
    } catch (error) {
      // TODO TOAST
      //   Toast.show({
      //     type: 'error',
      //     text1: error?.message || t('toastErrorDefault.error'),
      //     props: {
      //       closable: true,
      //     },
      //   })
    }
  }, [changes, editQrcode, haveChanges, isQrCodeChanged, setUser])

  const tabs = useMemo(() => {
    return [
      {
        label: 'edit_profil.informations.title',
        slug: 'informations',
        navigation: '/dashboard/profile/informations',
        buttonLabel: 'edit_profil.preview',
        tabTitle: 'edit_profil.informations.title',
        tabSubtitle: 'edit_profil.informations.subtitle',
        buttonOnClick: () =>
          window.open(window.origin + `/${editCurrentUser?.customUrl}`),
        renderPreview: () => (
          <Phone>
            <ProfileContainer isPreview />
          </Phone>
        ),
        component: Informations,
        renderCustomButton: () => null,
        index: true,
      },
      {
        label: 'edit_profil.contents.title',
        slug: 'contents',
        navigation: '/dashboard/profile/contents',
        buttonLabel: 'edit_profil.preview',
        tabTitle: 'edit_profil.contents.title',
        tabSubtitle: 'edit_profil.contents.subtitle',
        buttonOnClick: () =>
          window.open(window.origin + `/${editCurrentUser?.customUrl}`),
        renderPreview: () => (
          <Phone>
            <ProfileContainer isPreview />
          </Phone>
        ),
        renderCustomButton: () => null,
        component: Contents,
      },
      {
        label: 'edit_profil.appearance.title',
        slug: 'appearance',
        navigation: '/dashboard/profile/appearance',
        buttonLabel: 'edit_profil.preview',
        tabTitle: 'edit_profil.appearance.title',
        tabSubtitle: 'edit_profil.appearance.subtitle',
        buttonOnClick: () =>
          window.open(window.origin + `/${editCurrentUser?.customUrl}`),
        renderPreview: () => (
          <Phone>
            <ProfileContainer isPreview />
          </Phone>
        ),
        renderCustomButton: () => null,
        component: Appearance,
      },
      {
        label: 'QR Code',
        slug: 'qr-code',
        navigation: '/dashboard/profile/qr-code',
        buttonLabel: 'edit_profil.preview',
        buttonOnClick: () => null,
        renderPreview: () => <QrCodePreview />,
        tabTitle: 'edit_profil.qr_code.title',
        tabSubtitle: 'edit_profil.qr_code.subtitle',
        renderCustomButton: () => <QrCodeFooter />,
        component: QrCode,
      },
      {
        label: 'businessCard.title_screen_preview',
        slug: 'business-card',
        navigation: '/dashboard/profile/business-card',
        buttonLabel: 'modal.businessCardOption.option2',
        tabTitle: 'Business card',
        tabSubtitle: 'edit_profil.informations.businesscard.subtitle',
        buttonOnClick: () =>
          window.open(window.origin + `/c/${selectedBusinessCard?.id}`),
        renderPreview: () => <BusinessCardPreview />,
        renderCustomButton: () => (selectedBusinessCard?.id ? null : <></>),
        component: BusinessCard,
      },
    ]
  }, [editCurrentUser, selectedBusinessCard?.id])

  useEffect(() => {
    setEditCurrentUser(user)
  }, [user])

  return (
    <EditProfileContext.Provider
      value={{
        haveChanges,
        editCurrentUser,
        setEditCurrentUser,
        onSubmit,
        tabs,
        isQrCodeChanged,
      }}
    >
      {children}
    </EditProfileContext.Provider>
  )
}

export function useEditCurrentUser() {
  return useContext(EditProfileContext)
}
