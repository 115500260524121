const generate_style_page = {
  image_generation: 'Generación de imágenes',
  regenerate: 'Regenerar',
  edit: 'Editar',
  download: 'Descargar',
  validate: 'Validar',
  image_downloaded: 'Imagen descargada exitosamente',
  companion_is_generating:
    'Un poco de paciencia... ¡Estoy generando tu imagen!',
  generation_done: '¡Y aquí está el resultado! Te gusta ?',
  good_to_know: 'Bueno Saber',
  good_to_know_texts: {
    1: '¿Ya probaste tu suerte en la máquina tragamonedas? ¡Dicen que algunos han ganado el jackpot!',
    2: '¿Sabías que al alcanzar 100 likes en una publicación, se planta un árbol? ¡Haz el bien compartiendo tus momentos favoritos!',
    3: '¿Conoces nuestra característica exclusiva de tarjetas de visita digitales? ¡Crea y comparte la tuya para destacarte en la red!',
    4: 'Mall llegará pronto! Espera increíbles descuentos en grandes marcas. ¡No olvides registrarte para ser el primero en saberlo!',
    5: '¿Te gustan los desafíos? Únete a nuestras tablas de clasificación de juegos y demuestra quién es el verdadero campeón en la aplicación!',
    6: 'Recoge semillas y gotas para acceder a contenido especial o avanzar más rápido en nuestros juegos. ¡Cada interacción cuenta!',
    7: 'Transforma tus interacciones en un impacto positivo: cada vez que usas nuestra aplicación, contribuyes a iniciativas ecológicas.',
    8: 'Descubre nuestros paquetes exclusivos en la tienda: compra semillas, monedas, gotas o boletos y acelera tu progreso en la aplicación!',
    9: 'Todos los días, tienes la oportunidad de recuperar tus recursos diarios. ¡No olvides iniciar sesión para no perdértelos!',
    10: 'Con cada amigo que refieras, ganas recursos valiosos. Cuanto más invites, más ganas, ¡así que comparte tu código de referencia ahora!',
    11: 'Participa en nuestras misiones diarias para acumular aún más semillas y monedas. ¡Cada día trae nuevos desafíos y recompensas!',
    12: '¿Has visto un anuncio para obtener recursos gratis hoy? ¡Mira un anuncio y gana gotas al instante!',
    13: '¿Sabías que puedes seguir tus estadísticas en tiempo real en nuestra aplicación? ¡Descubre quién ve tus publicaciones y quién interactúa con ellas!',
    14: 'Cada publicación que recibe me gusta acerca a nuestra comunidad a la próxima plantación de árboles. ¡Participa activamente en nuestra misión verde!',
    15: 'Usa nuestras monedas para jugar en los juegos o para obtener ventajas exclusivas. Cuantas más tengas, más podrás hacer!',
    16: 'Descubre nuevas formas de ganar recompensas cada día. ¡Visita nuestra sección de desafíos y comienza a acumular beneficios ahora!',
  },
}

export default generate_style_page
