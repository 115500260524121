import React, { Suspense } from 'react'
import { Layout } from './Layout'
import { useScrollToTop } from '@/Hooks'
import { AuthProvider } from '@/Services/auth/AuthProvider'
import { ResizeProvider } from '@/ResizeProvider'
import { useGetWhitelabelBySlugQuery } from '@/Hooks/useGetWhitelabelBySlugQuery'
import SEO from '@/SEO'

const MainLayout = ({ children }) => {
  useGetWhitelabelBySlugQuery()
  useScrollToTop()
  return (
    <ResizeProvider>
      <AuthProvider>
        <Layout>
          <SEO />
          <div className="min-h-screen flex-1 overflow-x-hidden flex flex-col">
            <Suspense fallback={null}>{children}</Suspense>
          </div>
        </Layout>
      </AuthProvider>
    </ResizeProvider>
  )
}

export default MainLayout
