const onboarding = {
  page_title: 'Bienvenue',
  optional: 'facultatif',
  about_title: 'À propos',
  interests: 'Centres d’intérêts',
  selected: 'sélectionnés',
  calendar: 'Calendrier',
  username_title: 'Nom d’utilisateur',
  your_username: 'Votre nom utilisateur',
  referral_code: 'Code de parrainage',
  your_godfather: 'Votre parrain',
  about: {
    male: 'Masculin',
    female: 'Féminin',
    society: 'Société',
  },
  tags: {
    Business: 'Affaires',
    Creatives: 'Créatifs',
    Education: 'Éducation',
    Entertainment: 'Divertissement',
    Fashion: 'Mode',
    Government_Politics: 'Gouvernement et Politique',
    Food_Beverages: 'Nourriture et Boissons',
    Health_Wellness: 'Santé et Bien-être',
    Tech: 'Technologie',
    Travel_Tourism: 'Voyages et Tourisme',
    Other: 'Autre',
  },
  title: 'Parlez-nous de vous',
  subtitle: 'Pour une expérience personnalisée',
  who_are_you: 'Qui êtes-vous ?',
  username: "Votre nom d'utilisateur",
  username_information: 'Seules les lettres et les chiffres sont autorisés *',
  sponsorUID: 'Code parrain',
  firstname: 'Votre prénom',
  lastname: 'Votre nom de famille',
  lastname_title: 'Nom de famille',
  birth_date: 'Votre date de naissance',
  birth_date_title: 'Date de naissance',
  business_name: 'Le nom de votre entreprise',
  select_categories:
    'Sélectionnez trois catégories qui décrivent le mieux votre profil',
  continue: 'Continuer',
  verification_code: {
    verification: 'Vérification',
    title: 'Vérification de votre e-mail',
    subtitle:
      'Cliquez sur le lien ou entrez le code que nous vous avons envoyé.',
    back_to_verification: 'Aller à la vérification du compte',
    info: 'Nous avons envoyé le code de vérification à ',
    resend: 'Renvoyer l’email',
    verify_your_account: 'Vérifiez votre compte',
    enter_your_verification: 'Entrer votre code de vérification',
  },
  personalize_your: 'Personnalisez votre expérience',
  creating_your_profile: 'Création de votre profil',
  enter_your_information: 'Saisissez vos informations',

  step1: {
    welcome: 'Souhaitez-vous créer un profil personnel ou professionnel ?',
    profile: 'Profil',
    choose_individual:
      "Choisissez 'personnel' pour créer un profil à votre image ou 'professionnel' pour créer un profil à l'image de votre entreprise.",
    individual: {
      name: 'Prénom',
      title: 'Personnel',
      subtitle:
        'Créez un profil qui vous représente et interagissez avec les autres utilisateurs.',
    },
    business: {
      name: "Nom de l'entreprise",
      title: 'Entreprise',
      subtitle:
        'Créez un profil qui représente votre entreprise et interagissez avec les autres utilisateurs.',
    },
  },
  step2: {
    complete_your_budly: 'Complétez votre profil',
    introduce_yourself: 'Présentez-vous aux membre de la communauté.',
    your_profile_is: 'Votre profil est créé !',
    select_a_photo_and_a_banner:
      'Sélectionnez une photo et une bannière pour personnaliser votre profil !',
    create_your_profile: 'Créons votre profil',
    enter_your_first_name:
      "Saisissez votre prénom pour personnaliser votre profil. Si vous avez un code de parrainage, indiquez-le ici pour bénéficier d'avantages exclusifs !",
    help_others: 'Aidez les autres membres du réseau à mieux vous connaître.',
    describe_yourself: 'Décrivez-vous à la communauté',
  },
}

export default onboarding
