import Text from '@/Components/Texts/Text'
import styled, { css } from 'styled-components'
import { useEditCurrentUser } from '../../Contexts/EditProfileContext'
import { useCosmetics } from '../../Contexts/CosmeticsContext'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import LabeledSwitch from '@/Components/Controls/LabeledSwitch'
import ShapeChoice from '@/Components/ShapeChoice'
import ColorPickerField from '@/Components/ColorPickerField'

const Preview = styled.div`
  ${({ theme, editCurrentUser }) => css`
    background-color: ${editCurrentUser.styles.hideBtnBackground
      ? 'transparent'
      : theme.colors.white};
    border-color: ${editCurrentUser.styles.showBtnOutline
      ? editCurrentUser.styles.btnOutlineColor || '#000000'
      : theme.colors.grey1};
    border-width: 1px;
    width: 80px;
    height: 40px;
  `}
`

const BtnStyle = () => {
  const { editCurrentUser, setEditCurrentUser } = useEditCurrentUser()
  const cosmetics = useCosmetics()
  const { buttonShapes, btnOutlineColor } = useMemo(() => {
    return {
      buttonShapes: cosmetics?.btnShape,
      btnOutlineColor: cosmetics?.btnOutlineColor?.[0],
    }
  }, [cosmetics])

  const { t } = useTranslation()

  const onAddToCart = useCallback(
    item => {
      setEditCurrentUser(current => {
        return {
          ...current,
          styles: { ...current.styles, [item.type]: item.value },
        }
      })
    },
    [setEditCurrentUser],
  )

  return (
    <div className="w-full">
      <Text>{t('edit_profil.appearance.btn_style.shape')}</Text>
      <div className="my-4">
        <ShapeChoice name="btnShape" shapes={buttonShapes} preview={Preview} />
      </div>
      <Text>{t('edit_profil.appearance.btn_style.styles')}</Text>
      <div className="my-4">
        <LabeledSwitch
          title={t('edit_profil.appearance.btn_style.show_background')}
          value={!editCurrentUser.styles.hideBtnBackground || false}
          onChange={value =>
            setEditCurrentUser({
              ...editCurrentUser,
              styles: {
                ...editCurrentUser.styles,
                hideBtnBackground: !value,
                showBtnOutline: value
                  ? editCurrentUser.styles.showBtnOutline
                  : true,
              },
            })
          }
          variant="secondary"
        />
        <LabeledSwitch
          title={t('edit_profil.appearance.btn_style.show_outline')}
          value={editCurrentUser.styles.showBtnOutline || false}
          onChange={value => {
            setEditCurrentUser({
              ...editCurrentUser,
              styles: { ...editCurrentUser.styles, showBtnOutline: value },
            })
          }}
          disabled={editCurrentUser.styles.hideBtnBackground}
          className="mt-3"
          variant="secondary"
        />
        <LabeledSwitch
          title={t('edit_profil.appearance.btn_style.show_content_as_list')}
          value={(editCurrentUser.profileDisplay || 'row') === 'row'}
          onChange={value =>
            setEditCurrentUser({
              ...editCurrentUser,
              profileDisplay: value ? 'row' : 'icon',
            })
          }
          className="mt-3"
          variant="secondary"
        />
      </div>
      {editCurrentUser.styles.showBtnOutline && (
        <>
          <div className="flex flex-row justify-between items-center ">
            <Text>{t('edit_profil.appearance.btn_style.outline_color')}</Text>{' '}
          </div>
          <div className="my-4">
            <ColorPickerField
              value={editCurrentUser.styles.btnOutlineColor || '#000000'}
              onChange={value => {
                if (!btnOutlineColor.isAcquired) {
                  return onAddToCart({ ...btnOutlineColor, value })
                }
                setEditCurrentUser({
                  ...editCurrentUser,
                  styles: { ...editCurrentUser.styles, btnOutlineColor: value },
                })
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default BtnStyle
