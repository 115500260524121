const teams_page = {
  you: 'Vous',
  invite_members_title: 'Inviter des membres par email',
  invite_members_subtitle:
    'Ajoutez des membres de votre équipe par email et nous leur enverrons une invitation à rejoindre votre équipe. Les rôles peuvent être modifié ultérieurement.',
  invite_members_form: {
    email: 'Email',
    firstname: 'Prénom',
    job: 'Fonction',
    add_member: 'Ajouter un membre',
    total_price: 'Total',
  },
  see_profile: 'Voir profil',
  invite_members_button: 'Inviter',
  know_more_about_roles_1: 'En savoir plus à propos',
  know_more_about_roles_2: 'des rôles des membres de l’équipe',
  this_member: 'ce membre',
  number_products: '{{productsLength}} produit{{plurial}}',
  edit: 'Éditer',
  manage: 'Gérer',
  details: 'Détails',
  duplicate: 'Dupliquer',
  the_email_has_been: "L'email a bien été envoyé à votre membre.",
  invite_member: 'Inviter le membre',
  send_invitations_directly: 'Envoyer directement les invitations par mail',
  invitation_sent: 'Invitation envoyée',
  edit_selection: 'Éditer la sélection',
  search_for_a_member: 'Rechercher un membre par son nom ...',
  confirm_field_lock: 'Confirmer le {{lockdown}} des champs',
  lockdown_title: 'verrouillage',
  lockdown_subtitle: 'verrouiller',
  unlock_title: 'déverrouillage',
  unlock_subtitle: 'déverrouiller',
  are_you_sure:
    'Êtes-vous sûr de vouloir {{lockdown}} tous les champs pour ce membre "{{firstName}} {{lastName}}" ? Cette action est irréversible.',
  select_columns_to_include: 'Sélectionnez les colonnes à inclure.',
  we_found: 'Nous avons trouvé',
  columns_to_use: ' colonnes à utiliser.',
  statistics: 'Statistiques',
  view_team_statistics: 'Retrouvez les statistiques principales de vos équipes',
  team_space: 'Espace Team',
  team_member_information:
    'Retrouvez toutes les informations concernant les membres de votre équipe',
  loading_member_data: 'Chargement des données de tous les membres...',
  active_members: 'Membres actifs',
  member: 'membres',
  inactive_members: 'Membres inactifs',
  pending_invited_members:
    'Retrouvez tous les membres invités en attente d’activation',
  import_csv: 'Importer CSV',
  invite_members: 'Inviter membres',
  invitation: 'Invitation',
  invite_members_by_csv: 'Inviter des membres par CSV',
  invite_members_by_csv_subtitle:
    'Ajoutez une liste des membres de votre équipe avec un fichier CSV et nous leur enverrons une invitation. Les rôles peuvent être modifié ultérieurement.',
}

export default teams_page
