import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'whitelabel',
  initialState: { slug: null },
  reducers: {
    setWhitelabelSlug(state, { payload }) {
      state.slug = payload
    },
  },
})

export const { setWhitelabelSlug } = slice.actions

export default slice.reducer
