const duplicateMember = build =>
  build.mutation({
    query: ({ data, uid, whitelabelSlug }) => {
      return {
        url: `/members/${whitelabelSlug}/duplicate/${uid}`,
        method: 'POST',
        body: data,
      }
    },
    invalidatesTags: ['TeamMembers'],
  })

export default duplicateMember
