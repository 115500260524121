const signin = {
  title_page: 'Página principal',
  log_in: 'Iniciar sesión',
  welcome_back: '¡Bienvenido de nuevo a la comunidad de {{whitelabelName}}!',
  do_not_have: '¿Todavía no tienes una cuenta?',
  register: 'Registrarse',
  home: {
    title: 'Tu perfil digital',
    actions: {
      signin: 'Iniciar sesión',
      scan: 'Escanear un producto',
    },
  },
  inputs: {
    label_email: 'Correo electrónico',
    email: 'Tu correo electrónico',
    label_password: 'Contraseña',
    password: 'Tu contraseña',
    please_complete: 'Por favor, complete todos los campos del formulario.',
  },
  others: {
    forgot: 'contraseña olvidada?',
    continue: 'Seguir',
    welcome: 'Bienvenido de nuevo !',
    report_problem: 'Informar un problema',
    rgpd_request: 'Solicitud de GDPR',
    faqs: 'FAQs',
    remember_me: 'Se acuerda de mi',
    password_reset_link_sent:
      'El enlace de restablecimiento ha sido enviado a su dirección de correo electrónico.',
  },
  img: {
    welcome_to_budly: 'Bienvenido a {{whitelabelName}}',
    create_your_digital: 'Crea tu perfil digital según tus deseos y tu imagen',
  },
}

export default signin
