const parametre = {
  title: 'Settings',
  title_bloc1: 'PRODUCTS',
  title_bloc2: 'GENERAL',
  title_bloc3: 'SECURITY',
  title_bloc4: 'SUPPORT',
  community_title: 'COMMUNITY',
  title_bloc6: 'ABOUT',
  produit_gopop: {
    activer_produit: {
      title: 'Activate a product',
      select: 'Select an activation method',
      description:
        'Choose from these options which one you want to use to activate your product.',
      subtitle: 'Scan the QRCode on the product packaging.',
      detect_product: 'Detect my product',
      subtitle2: 'Approach the product to the back of your phone',
      productDetected: 'Product detected',
      productActivate: 'Press to activate',
      manuellement: {
        title: 'Manually add my product',
        subtitle: 'Enter the code written on the product packaging.',
        modal: {
          title: 'New product',
          subtitle: 'Add the code located on the product packaging',
          input: 'Enter the product code',
          btn: 'Validate',
        },
      },
    },
    my_products: {
      insight: 'My products',
      title: 'Find all your products',
      subtitle: 'Activate, deactivate and modify all your products',
      modal_delete: {
        title: 'Product deletion',
        subtitle: 'Are you sure you want to delete this product ?',
        btn: {
          delete_btn: 'Delete',
          cancel_btn: 'Cancel',
        },
        bottom_btn: {
          btn_add: 'Add',
          btn_get: 'Get',
        },
      },
    },
    get_product: {
      title: 'Get one of our products',
    },
    unlock_gpm_team: {
      title: 'Discover GPM Team',
      subtitle: 'Control your brand narrative with GPM Team',
      description:
        'GPM Team is an administrator dashboard that allows businesses to manage GPM Team business cards for their employees.',
      possibilities: 'As a GPM Team user you can:',
      possibility_1: 'Create, Edit and Delete Cards for your employees',
      possibility_2:
        'Update all your company cards when your company information changes (e.g. company address, company logo, or marketing links)',
      possibility_3: 'Make cards non-editable for certain users',
      possibility_4:
        "Collect and export contact details of the people who receive your employees' business cards",
      access: 'Plus, all your employees get access to GPM Team !',
      bottom_btn: 'Discover',
    },
  },

  services: {
    title: 'SERVICES',
    gpm_Travel: {
      option_title: 'Discover GPM Travel',
      title: 'Save big on travel with our secret fares and amazing discounts!',
      description:
        "Check out our backdoor to wholesale travel fares and hidden discounts that online retailers don't want you to know about. Join us to save thousands of dollars every year on your travels!",
      title_possibilities: 'As a GPM Travel user, you can:',
      possibility_1:
        'Enjoy up to 70% off your first two nights at over two million hotels worldwide with our exclusive offers.',
      possibility_2:
        'Explore the world for a third of the usual price with our great offers and exclusive rates.',
      possibility_3:
        "Don't let high travel prices hold you back. With our subscription for just $99 per year, the world is at your fingertips.",
    },
    gpm_Card: {
      option_title: 'Discover GPM Card',
      title:
        'Create and Share Your Ultimate Digital Business Card with Our Unique App!',
      description:
        'Forget outdated paper business cards. Our revolutionary app is everything you need to create, personalize and share your digital business card. Explore endless possibilities for personalization, easily share your contact details, track your views and clicks, all in a user-friendly digital experience.',
      title_possibilities: 'As a GPM Card user, you can:',
      possibility_1: 'Create a unique card.',
      possibility_2: 'Exchange your contact details with one click.',
      possibility_3: 'Real-Time Statistics Track views and clicks live.',
      possibility_4: 'Without paper cards, more environmentally friendly.',
      possibility_5: 'Maximum Accessibility Your card, available everywhere.',
    },
    gpm_Wallet: {
      option_title: 'Discover GPM Wallet',
      title:
        'Invest in the future with our exceptional yielding companions and plant trees for a positive impact!',
      description:
        "“Check out our unique collection of companions that offer incredible returns, up to 300% per year. By investing in our companions, not only do you make impressive profits, but you also contribute to reforestation. For every companion card created, We're planting a tree, which means your profits contribute to a greener, more prosperous future.",
      title_possibilities: 'As a GPM Wallet user, you can:',
      possibility_1:
        'Our companions offer an exceptional potential return, up to 300% per year.',
      possibility_2: 'Easily track your profits generated by our companions.',
      possibility_3:
        'Each companion card created is equivalent to planting a tree, for a greener world.',
    },
  },
  modal_menu: {
    settings: 'Settings and preferences',
    bookmarks: 'My bookmarks',
    share_QR_code: 'Share my profile',
    scan_QR_code: 'Scan a QR code',
  },
  general: {
    personal_info: {
      title: 'Personal informations',
      btn: 'Register',
      inputs: {
        my_link: 'My personalized link',
        email: 'Email',
        number: 'Phone number',
      },
    },

    link_preference: {
      title: 'Display preferences',
      btn: 'Register',
      subtitle: 'Choose your profile link display preference',
      list_shap: {
        title: 'As a list',
        subtitle: 'Icon, title and description visible',
      },
      icon_shap: {
        title: 'As an icon',
        subtitle: 'Link icon only',
      },
    },

    seo: {
      top: {
        title: 'Metadata preview',
        sentence: 'My custom seo',
      },
      bottom: {
        title: ' Edit your information',
        email: 'Title',
        description: 'Meta description',
      },
    },

    statistique: {
      title: 'My statistics',
      title_tabs1: 'Global',
      title_tabs2: 'Views',
      title_tabs3: 'Unique views',
      title_tabs4: 'Clicks',
      title_tabs5: 'CTR',
      title_tabs6: 'Device View',
      title_tabs7: 'Country view',
      title_tabs8: 'Top social networks',
      title_tabs9: 'Top links',
      title_tabs10: 'Top contacts',
      top: {
        title: 'Title',
        clicks: 'Clicks',
        graph: 'Graph',
        show_graph: 'Show on graph',
      },
      deleted_content: 'Deleted content',
      week_card_text: 'This week',
      no_data: 'No data available',
    },

    password: {
      insight: 'Password',
      title: 'Current Password',
      description: 'Enter your current password to be able to reset it',
      input: 'Current password',
      btn: 'Reset my password',
      notif: {
        email: 'The email address is already in use by another account.',
        error: 'An error occurred. Please try again.',
        psw: 'You must specify a password',
        psw2: 'The password must contain at least 6 characters of at least one letter and one number',
        psw3: 'Passwords do not match',
        psw4: 'The password or email you entered is incorrect. Please try again.',
        psw5: 'No user found with this identifier. The account may have been deleted.',
      },
    },

    newpassword: {
      title: 'New password',
      subtitle:
        'Your new password must be different from the one you used previously.',
      inputs: {
        new_psw: 'New password',
        confirm_new_psw: 'Confirm your new password',
      },
      btn: 'Change my password',
    },

    notification_settings: {
      title: 'Notifications',
    },

    darkmode: {
      title: 'Dark mode',
      enabled: 'Enabled',
      disabled: 'Disabled',
      device: 'Use device settings',
      theme_changed: 'Theme changed successfully!',
      activate_dark_mode:
        'Activate dark mode to reduce eye strain. Use the button below to switch between light and dark themes.',
    },

    languages: {
      insight: 'Languages',
      title: 'Language selection',
      subtitle:
        'The selected language will be applied to the entire application.',
      choice: {
        fr: 'French',
        en: 'English',
        es: 'Spanish',
      },
      save: 'Save',
    },
  },

  plan: {
    pro_abo: {
      title: 'Subscription Pro',
      subtitle: 'Access our Pro subscriptions directly on our website',
    },
  },

  share: {
    copy: 'Copy',
    contact_us: {
      title: 'Contact us',
      subtitle: 'We are here to help you, do not hesitate to contact us',
      type: {
        email: 'By email',
        direct_chat: {
          title: 'Contact Live Chat',
          subtitle: 'We are ready to answer you',
        },
      },
    },

    share_friend: {
      title: 'Share to a friend',
    },
    referral: {
      title: 'Refer a friend',
      module_title: 'Win 10 seeds, 10 drops and 10 coins from your friends.',
      reward_text:
        'Earn 10 seeds, 10 drops and 10 coins for each invited friend!',
      reward_subtitle:
        'Get rewards for you and your friend for each validated referral.',
      referred_friends: 'See my <bold>{{friends}}</bold> referred friends',
      no_referred_friends: 'No friends referred',
      share_referral_link: 'Share your referral code',
      become_ambassador: 'Become an ambassador?',
      invite_friends: 'Invite friends',
      share_message: 'The application for your digital identity.',
      share_link: '🌳 Here is my sponsor code:',
      app_link:
        'To download the application click on the following link: https://budly.link/dl-app?w=gpm ',
      sponsored_friends: 'Sponsored friends',
      copy: 'Copy',
      referred_friend_all: 'All',
      search: 'Search for a person',
      referred: 'Referred',
      valid: 'Validated',
      pending: 'Pending',
      referrer: 'My referrer',
      finish_your_quests:
        'Finish your welcome quests to validate your referral',
      drops_rewarded: 'Water drops obtained',
      seeds_rewarded: 'Seeds obtained',
      coins_rewarded: 'Coins obtained',
      empty_results: {
        no_sponsored_person: 'No sponsored person found',
        it_looks_like_you:
          "It looks like you haven't sponsored this person yet.",
      },
    },
    explain_referral: {
      screen_title: 'Earn rewards',
      title: {
        main_text:
          'Earn <color>{{1}}</color>, <color>{{2}}</color> and <color>{{3}}</color> for each invited friend!',
        1: '10 seeds',
        2: '10 drops',
        3: '10 coins',
      },
      subtitle:
        'Get rewards for you and your friends for each validated referral!',
      subscribe_title: 'Sign up with your link',
      subscribe_text: 'Your friend must sign up with your referral code',
      finish_quest_title: 'Complete his welcome quests',
      finish_quest_text:
        'Your friend must complete his welcome quests to validate the referral',
      collect_reward_title: 'Collect your rewards',
      collect_reward_text:
        'Once the welcome quests are completed, you and your friend will receive 10 seeds, 10 drops and 10 coins for free.',
    },
    referred_friends: {
      title: 'Vos filleul {{number}}',
    },
    review: {
      title: 'Leave us a review',
    },

    faq: {
      title: 'FAQS',
    },

    deconnect: {
      title: 'Sign out',
    },

    delete_acc: {
      delete: 'Delete my account',
    },
  },
  about: {
    privacy: 'Privacy policy',
    conditions: 'Terms of use',
    version: 'Version',
  },
}

export default parametre
