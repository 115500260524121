export default build =>
  build.query({
    query: ({ slug = 'whitelabel', domain }) => {
      console.log(`/whitelabel/domain/${slug}/${domain}`)
      return {
        url: `/whitelabel/domain/${slug}/${domain}`,
        method: 'GET',
      }
    },
    providesTags: ['Whitelabel'],
  })
